import React, { useState, useEffect } from 'react';
import { AlertsContainer, ModelStyleContainer } from './style';
import Loader from 'components/loading/loading';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { getDataFromCollection } from 'utils/firebaseDB';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const columns = [
  { id: 'id', label: 'Ticket ID' },
  { id: 'description', label: 'Description' },
  { id: 'usertype', label: 'User Type' },
  { id: 'name', label: 'Name' },
  { id: 'email', label: 'Email' },
  { id: 'phoneNumner', label: 'Mobile Number' },
  { id: 'createdAt', label: 'Date Created' },
  { id: 'status', label: 'Status' }
];

const Alerts = () => {
  const [alertsData] = useState([
    {
      id: 1,
      description: 'To get the current day and time in JavaScript, yo',
      usertype: 'Driver',
      name: 'Mariam',
      email: 'Johen@gmail.com',
      phoneNumner: '923057976321',
      createdAt: '01/03/2024'
    }
  ]);
  const [modalState, setModalState] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(14);

  const handleClose = () => setModalState(false);

  useEffect(() => {
    getDataFromCollection('alerts')
      .then(() => {
        setisLoading(false);
        // setAlertData(result);
      })
      .catch((error) => {
        setisLoading(false);
        console.error('Error fetching data:', error);
      });
  }, []);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1300,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 1
  };

  return (
    <>
      <AlertsContainer>
        <div className="alertHeader">
          <h1>Support</h1>
          <div className="rider_table_filter">
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Filter</InputLabel>
                <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Filter">
                  <MenuItem value="Compeleted">New</MenuItem>
                  <MenuItem value="Cancelled">In Progress</MenuItem>
                  <MenuItem value="Placed">Solved</MenuItem>
                  <MenuItem value="OnTheWay">Closed</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>
        </div>

        {isLoading ? (
          <Loader />
        ) : (
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map((column, i) => (
                      <TableCell key={i} align={column.align} style={{ minWidth: column.minWidth }}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {alertsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => setModalState(true)}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.id === 'description' ? (
                                <p className="textellipses">{value}</p>
                              ) : column.id === 'status' ? (
                                <select
                                  className="supportalerts_select"
                                  onClick={(e) => e.stopPropagation()} // Stop event propagation
                                >
                                  <option>New</option>
                                  <option>Closed</option>
                                  <option>In Progress</option>
                                  <option>Solved</option>
                                </select>
                              ) : (
                                value
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[14, 25, 100]}
              component="div"
              count={alertsData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
      </AlertsContainer>
      <Modal open={modalState} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <ModelStyleContainer>
            <div className="ModelStyleContainer_header">
              <h1>#1</h1>
              <div className="ModelStyleContainer_header_cross">
                <p>01/03/2024</p>
                <div
                  className="ModelStyleContainer_header_crossbutton"
                  role="button"
                  tabIndex={0}
                  onKeyDown={(e) => console.log(e)}
                  onClick={handleClose}
                >
                  X
                </div>
              </div>
            </div>
            <div className="ModelStyleContainer_body">
              <div className="ModelStyleContainer_body_content_personal_col">
                <div className="ModelStyleContainer_body_content_personal_col_row">
                  <h1>User Name:</h1>
                  <h2>{'Johen Marke'}</h2>
                </div>
                <div className="ModelStyleContainer_body_content_personal_col_row">
                  <h1>Mobile Number:</h1>
                  <h2>{'923057976321'}</h2>
                </div>
              </div>
              <div className="ModelStyleContainer_body_content_personal_col">
                <div className="ModelStyleContainer_body_content_personal_col_row">
                  <h1>Email:</h1>
                  <h2>{'Johen@gmail.com'}</h2>
                </div>
                <div className="ModelStyleContainer_body_content_personal_col_row">
                  <h1>User Type:</h1>
                  <h2>{'Driver'}</h2>
                </div>
              </div>
            </div>
            <div className="ModelStyleContainer_description">
              <h1>Request Details</h1>
              <p>
                Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying
                on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available. Text commonly used to
                demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a
                placeholder before final copy is available.
              </p>
              <p>
                Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying
                on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available. Text commonly used to
                demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a
                placeholder before final copy is available.
              </p>
            </div>
            <div className='ModelStyleContainer_support_footer' >
         
            <div className="ModelStyleContainer_support_status">
              <p>Set Status</p>
              <select className="supportalerts_select">
                <option>New</option>
                <option>Closed</option>
                <option>In Progress</option>
                <option>Solved</option>
              </select>
            </div>
            <div className="ModelStyleContainer_support_Confirm">
        <button >Confirm</button>
      </div>
            </div>
            
          </ModelStyleContainer>
        </Box>
      </Modal>
    </>
  );
};

export default Alerts;
