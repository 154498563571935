import React, { useEffect, useState } from 'react';
import { RidesDetailMain } from './ridesStyle';
import { useNavigate, useParams } from 'react-router-dom';
import { getDocumentDetail } from '../../utils/firebaseDB';
import backArrow from 'assets/shalnyimgages/back arrow.svg';
import locationIcon from 'assets/shalnyimgages/locationIcon.png';
// import GoogleMapReact from 'google-map-react';
import { GoogleMap } from '@react-google-maps/api';
import Loading from 'components/loading/loading';

import { useJsApiLoader } from '@react-google-maps/api';

const RidesDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [rideDetail, setrideDetail] = useState(null);

  useEffect(() => {
    (async () => {
      getDocumentDetail('rides', id)
      .then((response) => {
        setrideDetail(response);
      })
      .catch((error) => {
        console.log(error , 'errorerrorerror');
      })
    })();
  }, [id]);

  const mapStyles = {
    height: '400px',
    width: '100%'
  };

  const defaultCenter = {
    lat: rideDetail?.toData?.lat,
    lng: rideDetail?.toData?.long
  };

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: '' || '',
    libraries: ['places']
  });

  const detailPageBackHandler = () => {
    navigate(`/rides`);
  };

  if (!rideDetail) {
    return <Loading />;
  }

  // let meters = rideDetail?.kms;
  // var kilometers = meters / 1000;
  // let calculateKilometers = kilometers.toFixed(0);

  return (
    <RidesDetailMain>
      <div className="detail_page_header">
        <div
          className="detail_page_header_back"
          role="button"
          tabIndex={0}
          onKeyDown={(e) => console.log(e)}
          onClick={detailPageBackHandler}
        >
          <img src={backArrow} alt="backArrow" />
          <h2>Back</h2>
        </div>
        <div className="detail_page_header_status">
          <h1>Rides Status</h1>
          <h2>
            {rideDetail?.rideStatus === 'Compeleted' ? (
              <span className="detail_page_header_status_completed">Completed</span>
            ) : rideDetail?.rideStatus === 'Placed' ? (
              <span className="detail_page_header_status_placed">Placed</span>
            ) : rideDetail?.rideStatus === 'Cancelled' ? (
              <span className="detail_page_header_status_cancelled">Cancelled</span>
            ) : (
              rideDetail?.rideStatus
            )}{' '}
          </h2>
        </div>
      </div>

      <div className="detail_page_content">
        {/* {isLoaded && (
          <GoogleMapReact
            bootstrapURLKeys={{
              key: 'AIzaSyAdMgeE3Wn3ZMaGEDaWGer4Z3tlVmlIbH0'
            }}
            center={{
              lat: Number(defaultCenter.lat),
              lng: Number(defaultCenter.lng)
            }}
            defaultZoom={11}
          >
            <div style={{ fontSize: '30px' }}>📍</div>
          </GoogleMapReact>
        )} */}

        {isLoaded && (
          <GoogleMap mapContainerStyle={mapStyles} zoom={10} center={defaultCenter}>
            <img src={locationIcon} alt="locationIcon" />
          </GoogleMap>
        )}
        <div className="ride_detail">
          <div className="ride_detail_user">
            <div className="ride_row">
              <h1>Rider Id</h1>
              <p>36020379478478</p>
            </div>
            <div className="ride_row">
              <h1>Customer</h1>
              <p>Smith Joeee</p>
            </div>
            <div className="ride_row">
              <h1>Vehicle Type</h1>
              <p>Normal</p>
            </div>
            <div className="ride_row">
              <h1>Pickup Date</h1>
              <p>10 December 2021</p>
            </div>
            <div className="ride_row">
              <h1>Pickup Time</h1>
              <p>04:57 AM</p>
            </div>
            <div className="ride_row">
              <h1>Drop-off Date</h1>
              <p>10 December 2021</p>
            </div>
            <div className="ride_row">
              <h1>Drop-off Time</h1>
              <p>05:30 AM</p>
            </div>
            <div className="ride_row">
              <h1>Pickup Address</h1>
              <p>Parth Comples Divanpara main RD, Rajkor
Gujrat 360001 </p>
            </div>
            <div className="ride_row">
              <h1>Drop-off Address</h1>
              <p>Parth Comples Divanpara main RD, Rajkor
Gujrat 360001 </p>
            </div>
          </div>
          <div className="ride_detail_vehical">
            <div className="ride_row">
              <h1>Driver</h1>
              <p>Smith Joeee</p>
            </div>
            <div className="ride_row">
              <h1>Total Distance</h1>
              <p>64 Km</p>
            </div>
            <div className="ride_row">
              <h1>Shlny Fees</h1>
              <p>10 AED</p>
            </div>
            <div className="ride_row">
              <h1>Total Fare</h1>
              <p>100 AED </p>
            </div>
            <div className="ride_row">
              <h1>Payment Type</h1>
              <p>Cash on delivery</p>
            </div>
          </div>
        </div>
      </div>
    </RidesDetailMain>
  );
};

export default RidesDetail;
