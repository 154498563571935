import styled from 'styled-components';

const DriverDetailPageContainer = styled.div`
  .detail_page_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    &_back {
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      h2 {
        font-family: DM Sans;
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
      }
    }
    &_actions {
      display: flex;
      align-items: center;
      gap: 21px;

      &_reject {
        padding: 13px, 14px, 13px, 14px;
        padding: 13px 25px;
        border-radius: 7px;
        border: 1px solid #9e9e9e;
        background-color: transparent;
        cursor: pointer;
      }
      &_approve {
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: 0px;
        text-align: center;
        color: #ffffff;
        background-color: #000000;
        border: none;
        padding: 13px 25px;
        border-radius: 7px;
        cursor: pointer;
      }
    }
  }
  .detail_page_body {
    display: grid;
    grid-template-columns: 150px 1fr;
    gap: 40px;
    padding: 27px;

    &_profile {
      width: 100%;
      text-align: center;
      img {
        width: 165px;
        height: 150px;
        object-fit: contain;
        border: 1px dashed gray;
        padding: 5px;
      }
      h1 {
        font-family: DM Sans;
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        margin: 5px 0;
      }
      p {
        font-family: DM Sans;
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.20000000298023224px;
        text-align: center;
        color: #ff9500;
        background-color: #fff4e4;
        padding: 7px 10px;
        border-radius: 7px;
        width: max-content;
      }
    }
    &_content {
      width: 100%;
      background-color: #ffffff;
      border-radius: 30px;
      padding: 24px;
      h1 {
        font-family: DM Sans;
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0px;
        color: #161616;
        margin-bottom: 20px;
      }
      &_row {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-bottom: 20px;
        gap: 40px;
        &_first {
          display: grid;
          grid-template-columns: 1fr 1fr;
          h2 {
            font-family: DM Sans;
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            letter-spacing: 0em;
            color: #868686;
          }
          p {
            font-family: DM Sans;
            font-size: 16px;
            font-weight: 400;
            line-height: 13px;
            letter-spacing: 0em;
            text-align: left;
            color: #3e4756;
          }
        }
        &_second {
          display: grid;
          grid-template-columns: 1fr 1fr;
          h2 {
            font-family: DM Sans;
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            letter-spacing: 0em;
            color: #868686;
          }
          p {
            font-family: DM Sans;
            font-size: 16px;
            font-weight: 400;
            line-height: 13px;
            letter-spacing: 0em;
            text-align: left;
            color: #3e4756;
          }
        }
      }
      &_id {
        h2 {
          font-family: DM Sans;
          font-size: 18px;
          font-weight: 500;
          line-height: 28px;
          letter-spacing: 0px;
          color: #161616;
          margin-bottom: 8px;
        }

        img {
          width: 100%;
          object-fit: contain;
        }
      }
    }
  }
  .customerDetailPage_body {
    display: grid;
    grid-template-columns: 200px 1fr;
    margin-top: 30px;
    gap: 20px;
    &_profile {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      
      img {
        width: 220px;
        height: 220px;
        object-fit: cover;
        border-radius: 100%;
      }
      &_status {
        color: #34995c;
        border-radius: 8px;
        background: rgba(33, 150, 83, 0.15);
        width: max-content;
        color: var(--Success-Success-Dark, #34995c);
        text-align: center;
        font-family: DM Sans;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px; /* 144.444% */
        letter-spacing: 0.2px;
        padding: 7px 16px;
      }
      &_rating {
        display: flex;
        gap: 5px;
        align-items: center;
        img {
          width: 20px;
          height: 20px;
        }
        p {
          color: #494949;
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
    &_content {
      &_stats {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
        &_card {
          border-radius: 20px;
          border: 1px solid var(--Text-Color-Text-Color-6, #f9f9f9);
          background: var(--Neutral-Color-Pure-White, #fff);
          box-shadow: 0px 0px 30px 0px rgba(47, 45, 107, 0.1);
          min-height: 115px;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          padding: 0 20px;
          h1 {
            color: var(--Secondary-Color-Secondary-2, #494949);
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 120% */
            color: #494949;
          }
          h2 {
            color: var(--Secondary-Color-Secondary-2, #494949);
            font-size: 24px;
            font-style: normal;
            color: #494949;
            font-weight: 700;
            line-height: 32px; /* 133.333% */
          }
        }
      }
      &_personal {
        border-radius: 20px;
        background: #fff;
        box-shadow: 0px 0px 30px 0px rgba(47, 45, 107, 0.1);
        padding: 20px;
        margin-top: 20px;
        h1 {
          color: #161616;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px; /* 155.556% */
        }
        &_col {
          display: grid;
          grid-template-columns: 1fr 1fr;

          &_row {
            display: grid;
            grid-template-columns: 150px 290px;
            margin-bottom: 20px;
            h1 {
              color: #868686;
              font-family: DM Sans;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 19px; /* 118.75% */
              word-wrap: break-word;
            }
            h2 {
              color: var(--black, #3e4756);
              font-family: DM Sans;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px; /* 81.25% */
              word-wrap: break-word;
            }
          }
        }
      }
      &_rides {
        border-radius: 20px;
        background: #fff;
        box-shadow: 0px 0px 30px 0px rgba(47, 45, 107, 0.1);
        padding: 20px;
        margin-top: 20px;
        h1 {
          color: #161616;
          font-family: DM Sans;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px; /* 155.556% */
        }
      }
    }
  }
  .information_container_bottom_line {
    border-bottom: 1px solid #ebebeb;
    margin-top: 3px;
    margin-bottom: 30px;
  }
  .documents_information {
    h1 {
      color: #161616;
      font-family: 'DM Sans';
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px; /* 155.556% */
      margin-bottom: 10px;
    }
  }
  .approval_data_detail {
    display: flex;
    align-items: center;
    gap: 50px;
    margin-bottom: 20px;

    &_row {
      display: flex;
      align-items: center;
      gap: 20px;
      h1 {
        color: #868686;
        font-family: 'DM Sans';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 19px; /* 118.75% */
      }
      h2 {
        color: var(--black, #3e4756);
        font-family: 'DM Sans';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 13px; /* 81.25% */
      }
    }
  }
  .documents_container {
    display: flex;
    gap: 20px;
    img {
      width: 400px;
      height: auto;
      object-fit: cover;
    }
  }
  .request_detail_header {
    margin: 30px 0;
    display: flex;
    align-items: center;
    gap: 50px;
    /* border-radius: 20px;
        background: #fff;
        box-shadow: 0px 0px 30px 0px rgba(47, 45, 107, 0.1);
        padding: 30px 20px; */
    .request_detail_header_col_row {
      display: flex;
      align-items: center;
      gap: 20px;
      h1 {
        color: #161616;
        font-family: 'DM Sans';
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
      }
      h2 {
        color: var(--black, #3e4756);
        font-family: 'DM Sans';
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 13px;
      }
    }
  }
`;

const ModelRejection = styled.div`
  .ModelRejection_header {
    border-radius: 8px 8px 0px 0px;
    border-bottom: 1px solid var(--Gray-300, #d0d5dd);
    background: #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    h1 {
      color: var(--Gray-700, #344054);

      /* Text/Text lg/Semibold */
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 34px; /* 188.889% */
    }
    &_crossbutton {
      font-size: 20px;
      cursor: pointer;
    }
  }
  .ModelRejection_body {
    padding: 20px;
    &_container {
      h1 {
        color: #161616;
        font-family: DM Sans;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px; /* 155.556% */
      }
      &_col_row {
        display: flex;
        align-items: center;
        gap: 30px;
        h1 {
          color: #161616;
          font-family: 'DM Sans';
          font-size: 15px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px; /* 186.667% */
        }
        h2 {
          color: var(--black, #3e4756);
          font-family: 'DM Sans';
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 13px; /* 86.667% */
        }
      }
    }
    .rejectionReason_item {
      display: grid;
      grid-template-columns: 2fr 1fr;
      align-items: center;
      margin-top: 10px;
      h2 {
        color: var(--Gray-600, #475467);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 187.5% */
      }
    }
    &_reasons {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 30px;
      margin-top: 30px;
      &_reason {
        display: flex;
        align-items: center;
        p {
          color: #161616;
          font-family: 'DM Sans';
          font-size: 15px;
          font-style: normal;
          font-weight: 700;
          line-height: 15px; /* 186.667% */
          width: 100%;
        }
      }
    }
    &_action {
      text-align: center;
      margin-top: 50px;
      &_approve {
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: 0px;
        text-align: center;
        color: #ffffff;
        background-color: #000000;
        border: none;
        padding: 13px 25px;
        border-radius: 7px;
        cursor: pointer;
      }
    }
    &_errorMessage{
      color: red;
      font-weight: 500;
      margin-top: 20px;
    }
  }
`;

const CustomTableContainer = styled.div`
  box-shadow: 0px 0px 30px 0px #2f2d6b1a;
  padding: 25px 13px;
  border-radius: 20px;

  h1 {
    font-family: DM Sans;
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    margin-bottom: 20p;
  }

  .request_detail_handler {
    border: 1px solid #000000;
    background: #ffffff;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.20000000298023224px;
    text-align: center;
    border-radius: 7px;
    cursor: pointer;
  }
  .rider_table_filter{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  .table_search_input{
    width: 100%;
    border: 1px solid #B6B6B6;
    border-radius: 5px;
    padding: 10px;
    outline:  none;
    color: #B6B6B6;
    font-size: 16px;
  }
`;

export { CustomTableContainer, DriverDetailPageContainer, ModelRejection };
