import styled from 'styled-components';

const DriverContainer = styled.div`
  .driver_table_btn {
    button {
      border: 1px solid #000000;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0.20000000298023224px;
      text-align: center;
      background-color: transparent;
      cursor: pointer;
      border-radius: 7px;
    }
  }
`;

const DriverDetailPageContainer = styled.div`
  .detail_page_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &_back {
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      h2 {
        font-family: DM Sans;
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        margin-bottom: 8px;
      }
    }
    &_actions {
      display: flex;
      align-items: center;
      gap: 21px;

      &_reject {
        padding: 13px, 14px, 13px, 14px;
        padding: 13px 25px;
        border-radius: 7px;
        border: 1px solid #9e9e9e;
        background-color: transparent;
        cursor: pointer;
      }
      &_approve {
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: 0px;
        text-align: center;
        color: #ffffff;
        background-color: #000000;
        border: none;
        padding: 13px 25px;
        border-radius: 7px;
        cursor: pointer;
      }
    }
  }
  .detail_page_body {
    display: grid;
    grid-template-columns: 150px 1fr;
    gap: 40px;
    padding: 27px;

    &_profile {
      width: 100%;
      text-align: center;
      img {
        width: 165px;
        height: 150px;
        object-fit: contain;
        border: 1px dashed gray;
        padding: 5px;
      }
      h1 {
        font-family: DM Sans;
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        margin: 5px 0;
      }
      p {
        font-family: DM Sans;
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.20000000298023224px;
        text-align: center;
        color: #ff9500;
        background-color: #fff4e4;
        padding: 7px 10px;
        border-radius: 7px;
        width: max-content;
      }
    }
    &_content {
      width: 100%;
      background-color: #ffffff;
      border-radius: 30px;
      padding: 24px;
      h1 {
        font-family: DM Sans;
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0px;
        color: #161616;
        margin-bottom: 20px;
      }
      &_row {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-bottom: 20px;
        gap: 40px;
        &_first {
          display: grid;
          grid-template-columns: 1fr 1fr;
          h2 {
            font-family: DM Sans;
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            letter-spacing: 0em;
            color: #868686;
          }
          p {
            font-family: DM Sans;
            font-size: 16px;
            font-weight: 400;
            line-height: 13px;
            letter-spacing: 0em;
            text-align: left;
            color: #3e4756;
          }
        }
        &_second {
          display: grid;
          grid-template-columns: 1fr 1fr;
          h2 {
            font-family: DM Sans;
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            letter-spacing: 0em;
            color: #868686;
          }
          p {
            font-family: DM Sans;
            font-size: 16px;
            font-weight: 400;
            line-height: 13px;
            letter-spacing: 0em;
            text-align: left;
            color: #3e4756;
          }
        }
      }
      &_id {
        h2 {
          font-family: DM Sans;
          font-size: 18px;
          font-weight: 500;
          line-height: 28px;
          letter-spacing: 0px;
          color: #161616;
          margin-bottom: 8px;
        }

        img {
          width: 100%;
          object-fit: contain;
        }
      }
    }
  }
`;

const InsuranceCompanyContainer = styled.div`
  .vahicle_header {
    display: flex;
    align-items: center;
    gap: 21px;
  }

  h2 {
    font-family: DM Sans;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    margin-bottom: 5px;
  }

  .vahicle_form_container {
    h1 {
      font-family: DM Sans;
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      margin: 23px 0;
    }
  }
  .vahicle_form_body {
    box-shadow: 0px 0px 30px 0px #2f2d6b1a;
    padding: 38px;
    border-radius: 20px;
    display: flex;
    gap: 30px;

    &_inputs {
      width: 100%;
      &_input {
        margin-bottom: 19px;
        input {
          width: 100%;
          height: 100%;
          border: 1px solid #e0e0e0;
          outline: none;
          padding: 13px 8px;
          border-radius: 4px;
        }
        select {
          width: 100%;
          height: 100%;
          border: 1px solid #e0e0e0;
          outline: none;
          padding: 13px 8px;
          border-radius: 4px;
        }
        p {
          font-family: DM Sans;
          font-size: 16px;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0px;
          text-align: left;
          margin-bottom: 8px;
        }
      }
    }
    &_second {
      width: 100%;

      &_upload {
        width: 100%;
        border: 1px solid #e0e0e0;
        height: 242px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;

        .file-upload {
          position: relative;
          display: inline-block;
        }

        .file-upload__label {
          display: block;
          padding: 1em 2em;
          color: #000;
          border-radius: 0.4em;
          transition: background 0.3s;
        }

        .file-upload__input {
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          font-size: 1;
          width: 0;
          height: 100%;
          opacity: 0;
        }

        &_btn {
          border: 1px dashed #dadadb;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          padding: 20px 30px;
          border-radius: 10px;
          cursor: pointer;

          img {
            width: 18px;
            height: 14px;
            margin-bottom: 6px;
          }
          p {
            font-family: DM Sans;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0em;
            text-align: center;
          }
        }
      }

      &_button {
        background-color: #000000;
        border: none;
        font-family: SF Pro Display;
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: 0px;
        text-align: center;
        color: #ffffff;
        padding: 12px 51px;
        border-radius: 10px;
        float: right;
        margin-top: 90px;
        cursor: pointer;
      }
    }
  }
`;

const CustomTableContainer = styled.div`
  box-shadow: 0px 0px 30px 0px #2f2d6b1a;
  padding: 25px 13px;
  border-radius: 20px;

  h1 {
    font-family: DM Sans;
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    margin-bottom: 20p;
  }

  .request_detail_handler {
    border: 1px solid #000000;
    background: #ffffff;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.20000000298023224px;
    text-align: center;
    border-radius: 7px;
    cursor: pointer;
  }
  .company_header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      background: #000000;
      font-family: DM Sans;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0px;
      text-align: center;
      color: #ffffff;
      padding: 10px 14px;
      border: none;
      border-radius: 8px;
      cursor: pointer;
    }
  }
`;

const CompanyDetailPage = styled.div`
  .detail_page_header_back {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  .insurence_company_detail {
    border-radius: 20px;
    gap: 10px;
    box-shadow: 0px 4px 24px 0px #00000014;
    padding: 20px 40px;
    margin-top: 44px;
    h1 {
      font-family: DM Sans;
      font-size: 24px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }
    &_content {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 30px;
      margin-top: 26px;
      &_data {
        display: flex;
        align-items: center;
        gap: 30px;

        h1 {
          font-family: DM Sans;
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          letter-spacing: 0em;
          text-align: left;
          color: #868686;
        }
        p {
          color: #3e4756;
          font-family: DM Sans;
          font-size: 16px;
          font-weight: 400;
          line-height: 13px;
          letter-spacing: 0em;
          text-align: left;
        }
      }
    }
  }
`;
export { DriverContainer, DriverDetailPageContainer, CustomTableContainer, InsuranceCompanyContainer, CompanyDetailPage };
