import React, { useState, useEffect } from 'react';
import { AlertsContainer, ModelStyleContainer } from './style';
import { Checkbox, FormControlLabel, FormHelperText } from '@mui/material';
import Loader from 'components/loading/loading';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { deleteDocumentHandler, getDataFromCollection } from 'utils/firebaseDB';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import Swal from 'sweetalert2';
// import { createUserWithEmailAndPassword } from 'firebase/auth';
// import { auth } from '../../utils/firebase';
import Chip from '@mui/material/Chip';
import { addRolesWithUserId, createPanelUser, updateCollectionWithData } from '../../utils/firebaseDB';
// import { deleteAuthenticationUser } from '../../utils/firebase';
import moment from 'moment';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios';

// import Swal from 'sweetalert2';

const columns = [
  { id: 'id', label: 'ID' },
  { id: 'email', label: 'Email' },
  { id: 'panelAccess', label: 'Role' },
  { id: 'createdAt', label: 'Invitation' },
  { id: 'status', label: 'Status' }
];

const Alerts = () => {
  const [alertsData] = useState([
    {
      id: 1,
      description: 'To get the current day and time in JavaScript, yo',
      role: 'Driver',
      name: 'Mariam',
      email: 'Johen@gmail.com',
      createdAt: '12-12-2023'
    }
  ]);
  const [modalState, setModalState] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(14);
  const [password, setPassword] = useState('');
  const [errorMessage, seterrorMessage] = useState(null);
  const [userEmail, setUserEmail] = useState('');
  const [loading, setloading] = useState(false);
  const [userData, setuserData] = useState([]);
  const [selectedUser, setselectedUser] = useState({});
  const [search, setSearch] = React.useState('');
  const [userRoles, setUserRoles] = useState({
    Dashboard: false,
    Rides: false,
    Customers: false,
    Drivers: false,
    Driver_Requests: false,
    Notification_Alerts: false,
    Support_Requests: false,
    Panel_management: false
  });
  const [detailShow, setdetailShow] = useState(false);
  const { Dashboard, Rides, Customers, Drivers, Driver_Requests, Notification_Alerts, Support_Requests, Panel_management } = userRoles;

  const handleClose = () => setModalState(false);

  const detailHandler = (item) => {
    setselectedUser(item);
    setModalState(true);
    setdetailShow(true);

    let object = userRoles;

    for (const key in object) {
      if (item.panelAccess.includes(key)) {
        object[key] = true;
      } else {
        object[key] = false;
      }
    }

    setUserRoles(object);
    setUserEmail(item.userEmail);
    setPassword(item.password);
  };
  useEffect(() => {
    getDataFromCollection('panelUser')
      .then((result) => {
        setisLoading(false);
        setuserData(result);
      })
      .catch((error) => {
        setisLoading(false);
        console.error('Error fetching data:', error);
      });
  }, []);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24
  };

  const formSubmitHandler = async (event) => {
    event.preventDefault();
    seterrorMessage(null);
    setloading(false);

    const trueKeys = [];
    for (const key in userRoles) {
      if (userRoles[key] === true) {
        trueKeys.push(key);
      }
    }

    let specificOrder = [
      'Dashboard',
      'Rides',
      'Customers',
      'Drivers',
      'Driver_Requests',
      'Notification_Alerts',
      'Support_Requests',
      'Panel_management'
    ];

    let sortedArray = specificOrder.filter((item) => trueKeys.includes(item));
    // let sortedArray = specificOrder.filter(item => trueKeys.includes(item)).map(item => item.toLowerCase());

    // await createUserWithEmailAndPassword(auth, userEmail, password)
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/create_new_user`, { email: userEmail, password: password })
      .then(async (userCredential) => {
        const user = userCredential.data.data;
        return user;
      })
      .then(async (user) => {
        await addRolesWithUserId(user?.uid, 'admin', sortedArray[0]);

        return user;
      })
      .then(async (user) => {
        let result = await createPanelUser({
          userEmail,
          password,
          panelAccess: sortedArray,
          createdAt: moment().format('DD/MM/YYYY'),
          uid: user?.uid
        });
        if (result.status == 200) {
          setModalState(false);
          setloading(false);

          setUserEmail('');
          setPassword('');
          setUserRoles({
            Rides: false,
            Customers: false,
            Drivers: false,
            Driver_Requests: false,
            Notification_Alerts: false,
            Support_Requests: false,
            Panel_management: false
          });

          Swal.fire('Created!', result.message, 'success');
        }
        return true;
      })
      .then(() => {
        getDataFromCollection('panelUser').then((result) => {
          setisLoading(false);
          setuserData(result);
        });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;

        if (errorCode === 'auth/email-already-in-use') {
          seterrorMessage('User Already Exist, Please use any other Email');
          Swal.fire('Error!', 'User Already Exist, Please use any other Emai', 'error');
        } else {
          seterrorMessage(errorMessage);
          Swal.fire('Error!', errorMessage, 'error');
        }
      });
  };

  const handleChange = (event) => {
    setUserRoles({
      ...userRoles,
      [event.target.name]: event.target.checked
    });
  };

  const deleteUserHandler = async () => {
    // await deleteAuthenticationUser()
    setloading(true);
    setuserData(userData.filter((user) => user.id !== selectedUser.id));
    await deleteDocumentHandler('panelUser', selectedUser.id);
    setModalState(false);
    setloading(false);

    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/delete_user/${selectedUser.uid}`);
    } catch (error) {
      console.log(error, 'here is eror');
    }
  };
  const updateUserHandler = async () => {
    setloading(true);
    const trueKeys = [];
    for (const key in userRoles) {
      if (userRoles[key] === true) {
        trueKeys.push(key);
      }
    }

    let specificOrder = [
      'Dashboard',
      'Rides',
      'Customers',
      'Drivers',
      'Driver_Requests',
      'Notification_Alerts',
      'Support_Requests',
      'Panel_management'
    ];

    let sortedArray = specificOrder.filter((item) => trueKeys.includes(item));

    // const updateDate = await updateCollectionWithData('panelUser', selectedUser.id, 'panelAccess', sortedArray);

    try {
      await Promise.all([
        await axios.put(`${process.env.REACT_APP_BACKEND_URL}/update_new_user`, { uid: selectedUser.uid, password: password }),
        await updateCollectionWithData('panelUser', selectedUser.id, 'panelAccess', sortedArray),
        await updateCollectionWithData('panelUser', selectedUser.id, 'password', password)
      ]);

      setModalState(false);
      setloading(false);
      Swal.fire('Created!', 'User detail Update', 'success');
    } catch (error) {
      setloading(false);
      console.log(error.response, 'errorerror', error.response.data);
      // const errorCode = error.code;
      const errorMessage = error.response.data.error.message;

      seterrorMessage(errorMessage);
      // Swal.fire('Error!', errorMessage, 'error');
    }

    // try {
    //   await axios.put(`${process.env.REACT_APP_BACKEND_URL}/update_new_user`, { uid: selectedUser.uid, password: password });
    // } catch (error) {
    //   console.log(error, 'here is eror');
    // }
  };

  const filteredData = userData
    ? userData
        .filter((client) => {
          if (!search) {
            return {
              ...client
            };
          }
          return client.id.toLowerCase().includes(search.toLowerCase()) || client.userEmail.toLowerCase().includes(search.toLowerCase());
        })
        .map((clientRecord) => {
          return {
            ...clientRecord,
            name: `${clientRecord.name} ${clientRecord.name}`
          };
        })
    : [];
  return (
    <>
      <AlertsContainer>
        <div className="alertHeader">
          <h1>Panel Management</h1>
          <div className="alertHeader">
            <Box>
              <input className="table_search_input" placeholder="Search" onChange={(e) => setSearch(e.target.value)} />
            </Box>
            <div className="rider_table_filter">
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Filter</InputLabel>
                  <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Filter">
                    <MenuItem value="Cancelled">Active</MenuItem>
                    <MenuItem value="Compeleted">Disable</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
            <button
              onClick={() => {
                setModalState(true),
                  setUserRoles({
                    Rides: false,
                    Customers: false,
                    Drivers: false,
                    Driver_Requests: false,
                    Notification_Alerts: false,
                    Support_Requests: false,
                    Panel_management: false
                  }),
                  setUserEmail(''),
                  setPassword('');
                setdetailShow(false);
              }}
            >
              + Add New
            </button>
          </div>
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => detailHandler(row)}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.id === 'email' ? (
                                <p>{row?.userEmail}</p>
                              ) : column.id === 'panelAccess' ? (
                                row[column.id].map((role) => (
                                  <Stack key={role} mt={1} spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
                                    <Chip label={role.split('_').join(' ')} color="primary" size="small" variant="outlined" />
                                  </Stack>
                                ))
                              ) : column.id === 'status' ? (
                                <select onClick={(e) => e.stopPropagation()} className="supportalerts_select">
                                  <option>Active</option>
                                  <option>Disable</option>
                                </select>
                              ) : (
                                value
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[14, 25, 100]}
              component="div"
              count={alertsData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
      </AlertsContainer>
      <Modal open={modalState} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <ModelStyleContainer>
            <div className="ModelStyleContainer_header">
              <h1>Panel Mangement</h1>
              <div
                className="ModelStyleContainer_header_crossbutton"
                role="button"
                tabIndex={0}
                onKeyDown={(e) => console.log(e)}
                onClick={handleClose}
              >
                X
              </div>
            </div>
            <div className="ModelStyleContainer_body">
              <div className="ModelStyleContainer_body_content_personal_col">
                <div className="ModelStyleContainer_body_content_personal_col_row">
                  <p>Email Address</p>
                  <input readOnly={detailShow} type="Email" value={userEmail} onChange={(e) => setUserEmail(e.target.value)} />
                </div>
                <div className="ModelStyleContainer_body_content_personal_col_row">
                  <p>Password</p>
                  <input type="Name" name="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>
              </div>
              <div className="ModelStyleContainer_body_pageAccess">
                <h1>Pages Access</h1>
                <FormGroup>
                  <div className="ModelStyleContainer_body_pageAccess_checks">
                    <div className="information_container_check">
                      <h1>Dashboard</h1>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleChange}
                            name="Dashboard"
                            checked={Dashboard}
                            sx={{
                              color: '#474747',
                              '&.Mui-checked': {
                                color: '#474747'
                              }
                            }}
                          />
                        }
                      />
                    </div>
                    <div className="information_container_check">
                      <h1>Rides</h1>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleChange}
                            name="Rides"
                            checked={Rides}
                            sx={{
                              color: '#474747',
                              '&.Mui-checked': {
                                color: '#474747'
                              }
                            }}
                          />
                        }
                      />
                    </div>
                    <div className="information_container_check">
                      <h1>Customers</h1>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleChange}
                            name="Customers"
                            checked={Customers}
                            sx={{
                              color: '#474747',
                              '&.Mui-checked': {
                                color: '#474747'
                              }
                            }}
                          />
                        }
                      />
                    </div>
                    <div className="information_container_check">
                      <h1>Drivers</h1>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleChange}
                            name="Drivers"
                            checked={Drivers}
                            sx={{
                              color: '#474747',
                              '&.Mui-checked': {
                                color: '#474747'
                              }
                            }}
                          />
                        }
                      />
                    </div>
                    <div className="information_container_check">
                      <h1>Driver Requests</h1>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleChange}
                            name="Driver_Requests"
                            checked={Driver_Requests}
                            sx={{
                              color: '#474747',
                              '&.Mui-checked': {
                                color: '#474747'
                              }
                            }}
                          />
                        }
                      />
                    </div>
                  </div>
                  <div className="ModelStyleContainer_body_pageAccess_checks">
                    <div className="information_container_check">
                      <h1>Alerts</h1>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleChange}
                            name="Notification_Alerts"
                            checked={Notification_Alerts}
                            sx={{
                              color: '#474747',
                              '&.Mui-checked': {
                                color: '#474747'
                              }
                            }}
                          />
                        }
                      />
                    </div>
                    <div className="information_container_check">
                      <h1>Support Requests</h1>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleChange}
                            name="Support_Requests"
                            checked={Support_Requests}
                            sx={{
                              color: '#474747',
                              '&.Mui-checked': {
                                color: '#474747'
                              }
                            }}
                          />
                        }
                      />
                    </div>
                    <div className="information_container_check">
                      <h1>Panel management</h1>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleChange}
                            name="Panel_management"
                            checked={Panel_management}
                            sx={{
                              color: '#474747',
                              '&.Mui-checked': {
                                color: '#474747'
                              }
                            }}
                          />
                        }
                      />
                    </div>
                  </div>
                </FormGroup>
              </div>
              {errorMessage && (
                <FormHelperText error id="standard-weight-helper-text-email-login">
                  {errorMessage}
                </FormHelperText>
              )}
              <div className="alertformactionbutton">
                {detailShow ? null : (
                  <>
                    <button onClick={() => setModalState(false)} type="button" className="alertformactionbutton_cancel">
                      Cancel
                    </button>
                    <button disabled={loading} className="alertformactionbutton_invite" onClick={formSubmitHandler}>
                      {loading ? 'Loading' : 'Invite'}
                    </button>
                  </>
                )}
              </div>
              <div className="alertformactionbutton">
                {detailShow ? (
                  <>
                    <button disabled={loading} className="alertformactionbutton_cancel" onClick={deleteUserHandler}>
                      {loading ? 'Loading' : 'Delete'}
                    </button>
                    <button disabled={loading} className="alertformactionbutton_invite" onClick={updateUserHandler}>
                      {loading ? 'Loading' : 'Confirm'}
                    </button>
                  </>
                ) : null}
              </div>
            </div>
          </ModelStyleContainer>
        </Box>
      </Modal>
    </>
  );
};

export default Alerts;
