/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect , useCallback } from 'react';
import { DriverDetailPageContainer, ModelRejection } from './style';
import backArrow from 'assets/shalnyimgages/back arrow.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { getDocumentDetail, getDetails, updateCollectionWithData } from '../../utils/firebaseDB';
import { styled } from '@mui/material/styles';
import Loader from 'components/loading/loading';
import avatarpng from 'assets/shalnyimgages/avatarpng.png';
import cancelTick from 'assets/shalnyimgages/cancelTick.svg';
import pendingTick from 'assets/shalnyimgages/pendngTick.svg';
import approvedTick from 'assets/shalnyimgages/approvedTick.svg';
import noImage from 'assets/shalnyimgages/noImage.png';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { rejectionReasons } from './rejectionReasons';
import ratingStar from 'assets/shalnyimgages/star-7207.svg';
import Viewer from 'react-viewer';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const StyledTabs = styled((props) => <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />)({
  '& .MuiTabs-indicator': {
    backgroundColor: 'black'
  }
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  color: 'balck',
  '&.Mui-selected': {
    color: 'black',
    fontWeight: '900'
  }
}));
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  boxShadow: 24
};

const DriverDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [driverDetail, setDriverDetail] = useState(null);
  const [activeDetail, setActiveDetail] = useState();
  const [rejectModal, setRejectModal] = useState(false);
  // const [dataForApproval, setDataForApproval] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = useState(0);
  const [bankDetail, setbankDetail] = useState(null);
  const [docuemntDetail, setdocuemntDetail] = useState(null);
  const [vahicledetail, setvahicledetail] = useState(null);
  const [isFormSubmit, setisFormSubmit] = useState(false);
  const [reasonValues, setReasonValues] = useState({});
  const [formError, setformError] = useState('');

  const [previewImage, setPreviewImage] = useState('');
  const [visible, setVisible] = React.useState(false);

  const handleOpen = () => setRejectModal(true);
  const handleClose = () => setRejectModal(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    (async () => {
      try {
        const [detail, bankDetail, documentsDetail, vehicleDetail] = await Promise.all([
          getDocumentDetail('users', id),
          getDetails('banks', id),
          getDetails('documents', id),
          getDetails('vehicle', id)
        ]);

        setDriverDetail(detail);
        setbankDetail(bankDetail);
        setdocuemntDetail(documentsDetail);
        setvahicledetail(vehicleDetail);
        if (detail.idStatus === 'Pending') {
          setActiveDetail('Identification');
        }
        if (detail.bankStatus === 'Pending') {
          setActiveDetail('Bank_Information');
        }
        if (detail.licenseStatus === 'Pending') {
          setActiveDetail('Driving_License');
        }
        if (detail.vehicleStatus === 'Pending') {
          setActiveDetail('Vehicle_Detail');
        }
      } catch (error) {
        setIsLoading(false);
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [id]);

  const detailPageBackHandler = () => {
    navigate(`/driver_requests`);
  };

  const approveDetailHandler = async () => {
    setisFormSubmit(true);
    if (activeDetail === 'Vehicle_Detail') {
      let vehicleDetail = await updateCollectionWithData('vehicle', vahicledetail.uid, 'vehicleStatus', 'Approved');
      await updateCollectionWithData('users', id, 'vehicleStatus', 'completed');
      setvahicledetail(vehicleDetail);
    } else if (activeDetail === 'Identification') {
      let identificationDetail = await updateCollectionWithData('documents', docuemntDetail.uid, 'idStatus', 'Approved');
      await updateCollectionWithData('users', id, 'idStatus', 'completed');
      setdocuemntDetail(identificationDetail);
    } else if (activeDetail === 'Driving_License') {
      let documentDetail = await updateCollectionWithData('documents', docuemntDetail.uid, 'licenseStatus', 'Approved');
      await updateCollectionWithData('users', id, 'licenseStatus', 'completed');
      setdocuemntDetail(documentDetail);
    } else if (activeDetail === 'Bank_Information') {
      let bankInformationDetail = await updateCollectionWithData('banks', bankDetail.uid, 'bankStatus', 'Approved');
      await updateCollectionWithData('users', id, 'bankStatus', 'completed');
      setbankDetail(bankInformationDetail);
    }

    const promises = [getDetails('banks', id), getDetails('documents', id), getDetails('vehicle', id)];

    Promise.all(promises)
      .then(async (results) => {
        setisFormSubmit(false);

        if (
          results[0].bankStatus === 'Approved' &&
          results[1].idStatus === 'Approved' &&
          results[1].licenseStatus === 'Approved' &&
          results[2].vehicleStatus === 'Approved'
        ) {
          let result = await updateCollectionWithData('users', id, 'isApproved', true);
          setDriverDetail(result);
          navigate(`/driver_requests`);
        } else {
          console.log(
            results[0].bankStatus,
            results[1].idStatus,
            results[1].licenseStatus,
            results[2].vehicleStatus,
            'results[2].vehicleStatus'
          );
        }
      })
      .catch((error) => {
        // Handle errors if any of the promises fail
        console.error('Error:', error);
      });
  };

  function checkReasonValues(targetObject, validationObject, reasonType) {
    const reasonValues = targetObject.reasonValues;
    const targetReasonType = targetObject.reasonType;

    // Check if the target reasonType matches the one provided
    if (reasonType && targetReasonType !== reasonType) {
      return false;
    }

    for (const key in validationObject) {
      if (key !== 'reasonType' && key in validationObject) {
        const value = validationObject[key];
        if (key in reasonValues && reasonValues[key].includes(value)) {
          return true;
        }
      }
    }
    return false;
  }
  let activeReasons = rejectionReasons.find((detail) => detail.reasonType === activeDetail);

  function deepCopy(obj) {
    if (obj === null || typeof obj !== 'object') {
      return obj;
    }

    let copy = Array.isArray(obj) ? [] : {};

    for (let key in obj) {
      if (Object.hasOwnProperty.call(obj, key)) {
        copy[key] = deepCopy(obj[key]);
      }
    }

    return copy;
  }

  let copiedReasons = deepCopy(activeReasons);

  if (bankDetail?.bankLocation == 'Inside UAE') {
    delete copiedReasons?.reasonValues.SWIFT_Code;
    delete copiedReasons?.reasonValues.Bank_Currency;
  }
  //   function deepCopy(obj) {
  //     if (obj === null || typeof obj !== 'object') {
  //         return obj;
  //     }

  //     let copy = Array.isArray(obj) ? [] : {};

  //     for (let key in obj) {
  //         if (obj?.hasOwnProperty(key)) {
  //             copy[key] = deepCopy(obj[key]);
  //         }
  //     }

  //     return copy;
  // }

  // let activeReasonsInside = deepCopy(activeReasons);

  // console.log(activeReasonsInside , 'activeReasonsInside')

  const rejectDetailHandler = async () => {
    if (activeDetail === 'Vehicle_Detail' && checkReasonValues(activeReasons, reasonValues, activeDetail)) {
      await Promise.all([
        updateCollectionWithData('vehicle', docuemntDetail.uid, activeDetail, reasonValues),
        updateCollectionWithData('vehicle', docuemntDetail.uid, 'vehicleStatus', 'Cancelled'),
        updateCollectionWithData('users', id, 'vehicleStatus', 'rejected')
      ]);
      let vehicleDetail = await getDetails('vehicle', id);
      setvahicledetail(vehicleDetail);
      setRejectModal(false);
      setReasonValues({});
    } else if (activeDetail === 'Identification' && checkReasonValues(activeReasons, reasonValues, activeDetail)) {
      await Promise.all([
        updateCollectionWithData('documents', docuemntDetail.uid, activeDetail, reasonValues),
        updateCollectionWithData('documents', docuemntDetail.uid, 'idStatus', 'Cancelled'),
        updateCollectionWithData('users', id, 'idStatus', 'rejected')
      ]);
      let documentsDetail = await getDetails('documents', id);
      setdocuemntDetail(documentsDetail);
      setRejectModal(false);
      setReasonValues({});
    } else if (activeDetail === 'Driving_License' && checkReasonValues(activeReasons, reasonValues, activeDetail)) {
      await Promise.all([
        updateCollectionWithData('documents', docuemntDetail.uid, activeDetail, reasonValues),
        updateCollectionWithData('documents', docuemntDetail.uid, 'licenseStatus', 'Cancelled'),
        updateCollectionWithData('users', id, 'licenseStatus', 'rejected')
      ]);
      let documentsDetail = await getDetails('documents', id);
      setdocuemntDetail(documentsDetail);
      setRejectModal(false);
      setReasonValues({});
    } else if (activeDetail === 'Bank_Information' && checkReasonValues(activeReasons, reasonValues, activeDetail)) {
      await Promise.all([
        updateCollectionWithData('banks', bankDetail.uid, activeDetail, reasonValues),
        updateCollectionWithData('banks', bankDetail.uid, 'bankStatus', 'Cancelled'),
        updateCollectionWithData('users', id, 'bankStatus', 'rejected')
      ]);
      let updatedBankDetail = await getDetails('banks', id);
      setbankDetail(updatedBankDetail);
      setRejectModal(false);
      setReasonValues({});
    } else {
      setformError('Please select one rejection reason at least');
    }
  };

  const reasonSelectHandler = (event, reasonKey) => {
    setformError('');
    const newValue = event.target.value;
    // Update the reasonValues state with the new value
    setReasonValues((prevState) => ({
      ...prevState,
      [reasonKey]: newValue
    }));
  };

  const previewImageModalHandler = useCallback((img) => {
    setPreviewImage(img);
    setVisible(true);
  }, []);

  return (
    <DriverDetailPageContainer>
      {isLoading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <div className="detail_page_header">
            <div
              role="button"
              tabIndex={0}
              onClick={detailPageBackHandler}
              onKeyDown={(e) => console.log(e)}
              className="detail_page_header_back"
            >
              <img src={backArrow} alt="backArrow" />
              <h2>Back</h2>
            </div>
          </div>
          <div className="customerDetailPage_body">
            <div className="customerDetailPage_body_profile">
              <img src={driverDetail?.profilePic ? driverDetail?.profilePic : avatarpng} alt="profile" />
              <div className="customerDetailPage_body_profile_rating">
                <img src={ratingStar} alt="ratingStar" />
                <p>3.5</p>
              </div>
              {/* <h2 className="customerDetailPage_body_profile_status">{driverDetail?.isApproved ? 'Active' : 'Not Approved'}</h2> */}
            </div>
            <div className="customerDetailPage_body_content">
              <div className="customerDetailPage_body_content_personal">
                <Box sx={{ width: '100%' }}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <StyledTabs value={value} onChange={handleChange} aria-label="styled tabs example">
                      <StyledTab label="Personal Details" />
                      <StyledTab label="History" />
                    </StyledTabs>
                  </Box>
                  <CustomTabPanel value={value} index={0}>
                    <div className="information_container">
                      <h1>Personal Detail</h1>
                      <div className="information_container_bottom_line"></div>
                      <div className="customerDetailPage_body_content_personal_col">
                        <div className="customerDetailPage_body_content_personal_col_row">
                          <h1>Name</h1>
                          <h2>{driverDetail?.displayName}</h2>
                        </div>
                        <div className="customerDetailPage_body_content_personal_col_row">
                          <h1>Email</h1>
                          <h2>{driverDetail?.email}</h2>
                        </div>
                      </div>
                      <div className="customerDetailPage_body_content_personal_col">
                        <div className="customerDetailPage_body_content_personal_col_row">
                          <h1>Mobile Number</h1>
                          <h2>{driverDetail?.phoneNumber}</h2>
                        </div>
                        <div className="customerDetailPage_body_content_personal_col_row">
                          <h1>City</h1>
                          <h2>{driverDetail?.city}</h2>
                        </div>
                      </div>
                      <div className="customerDetailPage_body_content_personal_col">
                        <div className="customerDetailPage_body_content_personal_col_row">
                          <h1>State</h1>
                          <h2>{driverDetail?.state}</h2>
                        </div>
                        <div className="customerDetailPage_body_content_personal_col_row">
                          <h1>Country</h1>
                          <h2>{driverDetail?.country}</h2>
                        </div>
                      </div>
                    </div>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={1}>
                    <div className="customerDetailPage_body_content_stats">
                      <h1>History Detail</h1>
                      <div className="information_container_bottom_line"></div>
                    </div>
                  </CustomTabPanel>
                </Box>
              </div>
            </div>
          </div>
          <ButtonGroup sx={{ marginTop: '50px' }} color="secondary" mt variant="contained" aria-label="outlined primary button group">
            {driverDetail?.vehicleStatus === 'Pending' ? (
              <Button
                sx={{
                  backgroundColor: activeDetail === 'Vehicle_Detail' ? 'black' : 'secondary'
                }}
                onClick={() => setActiveDetail('Vehicle_Detail')}
              >
                Vehicle Detail
              </Button>
            ) : null}
            {driverDetail?.idStatus === 'Pending' ? (
              <Button
                sx={{
                  backgroundColor: activeDetail === 'Identification' ? 'black' : 'secondary'
                }}
                onClick={() => setActiveDetail('Identification')}
              >
                Identification
              </Button>
            ) : null}
            {driverDetail?.licenseStatus === 'Pending' ? (
              <Button
                sx={{
                  backgroundColor: activeDetail === 'Driving_License' ? 'black' : 'secondary'
                }}
                onClick={() => setActiveDetail('Driving_License')}
              >
                Driving License
              </Button>
            ) : null}
            {driverDetail?.bankStatus === 'Pending' ? (
              <Button
                sx={{
                  backgroundColor: activeDetail === 'Bank_Information' ? 'black' : 'secondary'
                }}
                onClick={() => setActiveDetail('Bank_Information')}
              >
                Bank Information
              </Button>
            ) : null}
          </ButtonGroup>

          <div className="customerDetailPage_body_content_personal">
            {activeDetail === 'Vehicle_Detail' ? (
              <div className="information_container">
                <div className="request_detail_header">
                  <div className="request_detail_header_col_row">
                    <h1>Request ID: </h1>
                    <h2>{driverDetail?.id}</h2>
                  </div>
                  <div className="request_detail_header_col_row">
                    <h1>Request Type:</h1>
                    <h2>{'Vehicle Detail'}</h2>
                  </div>
                  <div>
                    {vahicledetail?.vehicleStatus === 'Approved' ? (
                      <img src={approvedTick} alt="approvedTick" />
                    ) : vahicledetail?.vehicleStatus === 'Pending' ? (
                      <img src={pendingTick} alt="pendingTick" />
                    ) : vahicledetail?.vehicleStatus === 'Cancelled' ? (
                      <img src={cancelTick} alt="cancelTick" />
                    ) : null}
                  </div>
                </div>
                <div className="customerDetailPage_body_content_personal_col">
                  <div className="customerDetailPage_body_content_personal_col_row">
                    <h1>T.C Number</h1>
                    <h2>{vahicledetail?.tcNumber}</h2>
                  </div>
                  <div className="customerDetailPage_body_content_personal_col_row">
                    <h1>Model</h1>
                    <h2>{vahicledetail?.model}</h2>
                  </div>
                </div>
                <div className="customerDetailPage_body_content_personal_col">
                  <div className="customerDetailPage_body_content_personal_col_row">
                    <h1>Year of Expiry</h1>
                    <h2>{vahicledetail?.expiryDate}</h2>
                  </div>
                  <div className="customerDetailPage_body_content_personal_col_row">
                    <h1>Manufacturer</h1>
                    <h2>{vahicledetail?.manufacturer}</h2>
                  </div>
                </div>
                <div className="customerDetailPage_body_content_personal_col">
                  <div className="customerDetailPage_body_content_personal_col_row">
                    <h1>Truck Type</h1>
                    <h2>{vahicledetail?.truckType}</h2>
                  </div>
                  <div className="customerDetailPage_body_content_personal_col_row">
                    <h1>Color</h1>
                    <h2>{vahicledetail?.color}</h2>
                  </div>
                </div>
                <div className="customerDetailPage_body_content_personal_col">
                  <div className="customerDetailPage_body_content_personal_col_row">
                    <h1>Plate</h1>
                    <h2>{vahicledetail?.plate}</h2>
                  </div>
                  <div className="customerDetailPage_body_content_personal_col_row">
                    <h1>Plate Source</h1>
                    <h2>{vahicledetail?.plateSource}</h2>
                  </div>
                </div>
                <div className="customerDetailPage_body_content_personal_col">
                  <div className="customerDetailPage_body_content_personal_col_row">
                    <h1>Plate Kind</h1>
                    <h2>{vahicledetail?.plateKind}</h2>
                  </div>
                  <div className="customerDetailPage_body_content_personal_col_row">
                    <h1>Plate Number</h1>
                    <h2>{vahicledetail?.plateNumber}</h2>
                  </div>
                </div>
                <div className="information_container documents_information">
                  <h1>Car Registration Card</h1>
                  <div className="documents_container">
                    <img
                      src={vahicledetail?.cardFront || noImage}
                      alt="document"
                      onClick={() => previewImageModalHandler(vahicledetail?.cardFront)}
                    />
                    <img
                      src={vahicledetail?.cardBack || noImage}
                      alt="document"
                      onClick={() => previewImageModalHandler(vahicledetail?.cardBack)}
                    />
                  </div>
                </div>
                <div className="detail_page_header">
                  {vahicledetail.vehicleStatus === 'Pending' ? (
                    <>
                      <div className="detail_page_header_back"></div>
                      <div className="detail_page_header_actions">
                        {/* onClick={driverRejectHandler} */}
                        <button disabled={isFormSubmit} onClick={handleOpen} className="detail_page_header_actions_reject">
                          Reject
                        </button>

                        <button disabled={isFormSubmit} onClick={approveDetailHandler} className="detail_page_header_actions_approve">
                          Approve
                        </button>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            ) : activeDetail === 'Identification' ? (
              <div className="information_container">
                <div className="request_detail_header">
                  <div className="request_detail_header_col_row">
                    <h1>Request ID: </h1>
                    <h2>{driverDetail?.id}</h2>
                  </div>
                  <div className="request_detail_header_col_row">
                    <h1>Request Type:</h1>
                    <h2>{'Identification'}</h2>
                  </div>
                </div>
                <div className="approval_data_detail">
                  <div className="approval_data_detail_row">
                    <h1>ID Number</h1>
                    <h2>{docuemntDetail?.idNumber}</h2>
                  </div>
                  <div className="approval_data_detail_row">
                    <h1>Date of Expiry</h1>
                    <h2>{docuemntDetail?.idExpiryDate}</h2>
                  </div>
                  <div>
                    {docuemntDetail.idStatus === 'Approved' ? (
                      <img src={approvedTick} alt="approvedTick" />
                    ) : docuemntDetail.idStatus === 'Pending' ? (
                      <img src={pendingTick} alt="pendingTick" />
                    ) : docuemntDetail.idStatus === 'Cancelled' ? (
                      <img src={cancelTick} alt="cancelTick" />
                    ) : null}
                  </div>
                </div>
                <div className="documents_container">
                  <img
                    src={docuemntDetail?.idFront || noImage}
                    alt="document"
                    onClick={() => previewImageModalHandler(docuemntDetail?.idFront)}
                  />
                  <img
                    src={docuemntDetail?.idBack || noImage}
                    alt="document"
                    onClick={() => previewImageModalHandler(docuemntDetail?.idBack)}
                  />
                </div>
                <div className="detail_page_header">
                  {docuemntDetail.idStatus === 'Pending' ? (
                    <>
                      <div className="detail_page_header_back"></div>
                      <div className="detail_page_header_actions">
                        {/* onClick={driverRejectHandler} */}
                        <button disabled={isFormSubmit} onClick={handleOpen} className="detail_page_header_actions_reject">
                          Reject
                        </button>

                        <button disabled={isFormSubmit} onClick={approveDetailHandler} className="detail_page_header_actions_approve">
                          Approve
                        </button>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            ) : activeDetail === 'Driving_License' ? (
              <div className="information_container">
                <div className="request_detail_header">
                  <div className="request_detail_header_col_row">
                    <h1>Request ID: </h1>
                    <h2>{driverDetail?.id}</h2>
                  </div>
                  <div className="request_detail_header_col_row">
                    <h1>Request Type:</h1>
                    <h2>{'Driving License'}</h2>
                  </div>
                  <div>
                    {docuemntDetail.licenseStatus === 'Approved' ? (
                      <img src={approvedTick} alt="approvedTick" />
                    ) : docuemntDetail.licenseStatus === 'Pending' ? (
                      <img src={pendingTick} alt="pendingTick" />
                    ) : docuemntDetail.licenseStatus === 'Cancelled' ? (
                      <img src={cancelTick} alt="cancelTick" />
                    ) : null}
                  </div>
                </div>

                <div className="approval_data_detail">
                  <div className="approval_data_detail_row">
                    <h1>License Number</h1>
                    <h2>{docuemntDetail?.licenseNumber}</h2>
                  </div>
                  <div className="approval_data_detail_row">
                    <h1>Date of Expiry</h1>
                    <h2>{docuemntDetail?.licenseExpiry}</h2>
                  </div>
                </div>
                <div className="documents_container">
                  <img
                    src={docuemntDetail?.licenseFront || noImage}
                    alt="document"
                    onClick={() => previewImageModalHandler(docuemntDetail?.licenseFront)}
                  />
                  <img
                    src={docuemntDetail?.licenseBack || noImage}
                    alt="document"
                    onClick={() => previewImageModalHandler(docuemntDetail?.licenseBack)}
                  />
                </div>
                <div className="detail_page_header">
                  {docuemntDetail.licenseStatus === 'Pending' ? (
                    <>
                      <div className="detail_page_header_back"></div>
                      <div className="detail_page_header_actions">
                        {/* onClick={driverRejectHandler} */}
                        <button disabled={isFormSubmit} onClick={handleOpen} className="detail_page_header_actions_reject">
                          Reject
                        </button>

                        <button disabled={isFormSubmit} onClick={approveDetailHandler} className="detail_page_header_actions_approve">
                          Approve
                        </button>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            ) : activeDetail === 'Bank_Information' ? (
              <>
                <div className="information_container">
                  <div className="request_detail_header">
                    <div className="request_detail_header_col_row">
                      <h1>Request ID: </h1>
                      <h2>{driverDetail?.id}</h2>
                    </div>
                    <div className="request_detail_header_col_row">
                      <h1>Request Type:</h1>
                      <h2>{'Bank Information'}</h2>
                    </div>
                    <div>
                      {bankDetail.bankStatus === 'Approved' ? (
                        <img src={approvedTick} alt="approvedTick" />
                      ) : bankDetail.bankStatus === 'Pending' ? (
                        <img src={pendingTick} alt="pendingTick" />
                      ) : bankDetail.bankStatus === 'Cancelled' ? (
                        <img src={cancelTick} alt="cancelTick" />
                      ) : null}
                    </div>
                  </div>

                  <div className="customerDetailPage_body_content_personal_col">
                    <div className="customerDetailPage_body_content_personal_col_row">
                      <h1>Bank location</h1>
                      <h2>{bankDetail?.bankLocation}</h2>
                    </div>
                    <div className="customerDetailPage_body_content_personal_col_row">
                      <h1>Bank Name</h1>
                      <h2>{bankDetail?.bankName}</h2>
                    </div>
                  </div>
                  <div className="customerDetailPage_body_content_personal_col">
                    <div className="customerDetailPage_body_content_personal_col_row">
                      <h1>Account holder name</h1>
                      <h2>{bankDetail?.accountHolderName}</h2>
                    </div>
                    <div className="customerDetailPage_body_content_personal_col_row">
                      <h1>Account Number</h1>
                      <h2>{bankDetail?.accountNumber}</h2>
                    </div>
                  </div>
                  <div className="customerDetailPage_body_content_personal_col">
                    <div className="customerDetailPage_body_content_personal_col_row">
                      <h1>IBAN number</h1>
                      <h2>{bankDetail?.IBAN}</h2>
                    </div>
                  </div>
                  <div className="customerDetailPage_body_content_personal_col">
                    {bankDetail?.bankLocation !== 'Inside UAE' ? (
                      <>
                        <div className="customerDetailPage_body_content_personal_col_row">
                          <h1>SWIFT Code</h1>
                          <h2>{bankDetail?.swiftCode}</h2>
                        </div>
                        <div className="customerDetailPage_body_content_personal_col_row">
                          <h1>Bank currency</h1>
                          <h2>{bankDetail?.bankCurrency}</h2>
                        </div>
                      </>
                    ) : null}
                  </div>
                  <div className="information_container documents_information">
                    <h1>IBAN Certificate</h1>
                    <div className="documents_container">
                      <img
                        src={bankDetail?.documentPic || noImage}
                        alt="document"
                        onClick={() => previewImageModalHandler(bankDetail?.documentPic)}
                      />
                    </div>
                  </div>
                </div>
                <div className="detail_page_header">
                  {bankDetail.bankStatus === 'Pending' ? (
                    <>
                      <div className="detail_page_header_back"></div>
                      <div className="detail_page_header_actions">
                        {/* onClick={driverRejectHandler} */}
                        <button disabled={isFormSubmit} onClick={handleOpen} className="detail_page_header_actions_reject">
                          Reject
                        </button>

                        <button disabled={isFormSubmit} onClick={approveDetailHandler} className="detail_page_header_actions_approve">
                          Approve
                        </button>
                      </div>
                    </>
                  ) : null}
                </div>
              </>
            ) : (
              <h3>Admin does not received any verification request from driver </h3>
            )}
          </div>
        </>
      )}
      <Modal open={rejectModal} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <ModelRejection>
            <div className="ModelRejection_header">
              <h1>Rejection reasons</h1>
              <div
                className="ModelRejection_header_crossbutton"
                role="button"
                tabIndex={0}
                onKeyDown={(e) => console.log(e)}
                onClick={handleClose}
              >
                X
              </div>
            </div>
            <div className="ModelRejection_body">
              <div className="ModelRejection_body_container">
                <div className="ModelRejection_body_container_col_row">
                  <h1>Request ID: </h1>
                  <h2>{driverDetail?.id}</h2>
                </div>
                <div className="ModelRejection_body_container_col_row">
                  <h1>Request Type:</h1>
                  <h2>{activeDetail?.split('_').join(' ')}</h2>
                </div>
              </div>
              {copiedReasons ? (
                <div className="ModelRejection_body_reasons">
                  {Object.keys(copiedReasons?.reasonValues).map((item, i) => {
                    return (
                      <div key={i} className="ModelRejection_body_reasons_reason">
                        <p>{item.split('_').join(' ')}: </p>
                        <FormControl fullWidth>
                          <InputLabel id={`reason-select-label-${i}`}>Select Reason</InputLabel>
                          <Select
                            labelId={`reason-select-label-${i}`}
                            id={`reason-select-${i}`}
                            label={item}
                            onChange={(event) => reasonSelectHandler(event, item)}
                          >
                            {copiedReasons?.reasonValues[item]?.map((reason, index) => (
                              <MenuItem key={index} value={reason}>
                                {reason}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    );
                  })}
                </div>
              ) : null}

              <p className="ModelRejection_body_errorMessage">{formError}</p>
              <div className="ModelRejection_body_action">
                <button onClick={rejectDetailHandler} className="ModelRejection_body_action_approve">
                  Reject
                </button>
              </div>
            </div>
          </ModelRejection>
        </Box>
      </Modal>
      <Viewer
        visible={visible}
        drag={false}
        attribute={false}
        rotatable={true}
        scalable={false}

        loop={false}
        onMaskClick={() => {
          setVisible(false);
        }}
        noClose={true}
        onClose={() => {
          setVisible(false);
        }}
        images={[{ src: previewImage, alt: '' }]}
      />
    </DriverDetailPageContainer>
  );
};

export default DriverDetail;
