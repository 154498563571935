import styled from 'styled-components';

const AlertsContainer = styled.div`
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 30px 0px rgba(47, 45, 107, 0.1);
  padding: 25px 13px;
  .alertHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h1 {
      font-family: DM Sans;
      font-size: 24px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0em;
      margin-bottom: 20p;
    }

    button {
      background: #000000;
      font-family: DM Sans;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0px;
      text-align: center;
      color: #ffffff;
      padding: 10px 14px;
      border: none;
      border-radius: 8px;
      cursor: pointer;
    }
  }
  .textellipses {
    display: block;
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .action_button {
    border: 1px solid #000000;
    background: #ffffff;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.20000000298023224px;
    text-align: center;
    border-radius: 7px;
    cursor: pointer;
  }
  .supportalerts_select {
    background-color: red;
    border: none;
    padding: 8px 4px;
    color: #276ef1;
    background-color: #dae7ff;
    border-radius: 5px;
  }
`;

const ModelStyleContainer = styled.div`
  .ModelStyleContainer_header {
    border-radius: 8px 8px 0px 0px;
    border-bottom: 1px solid var(--Gray-300, #d0d5dd);
    background: #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 10px 20px;
    &_cross{
      display: flex;
    align-items: center;
    gap: 15px;
      p{
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 34px;
      margin: 0;
    }
    }
   
    h1 {
      color: var(--Gray-700, #344054);

      /* Text/Text lg/Semibold */
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 34px; /* 188.889% */
    }
    &_crossbutton {
      font-size: 20px;
      cursor: pointer;
    }
  }
  .ModelStyleContainer_body {
    padding: 20px 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    &_content_personal_col {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;

      h1 {
        color: #868686;
        font-family: DM Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 19px; 
        word-wrap: break-word;
      }
      h2 {
        color: var(--black, #3e4756);
        font-family: DM Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; 
        word-wrap: break-word;
      }
      &_row {
        display: flex;
        gap: 20px;
        h1 {
          color: #868686;
          font-family: DM Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 19px; /* 118.75% */
          word-wrap: break-word;
        }
        h2 {
          color: var(--black, #3e4756);
          font-family: DM Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px; /* 81.25% */
          word-wrap: break-word;
        }
      }
    }
  }
  .ModelStyleContainer_description{
    padding: 20px;
    /* margin: 20px; */
    margin-left: 20px;
    margin-bottom: 20px;
    margin-right: 20px;
    border: 1px solid #E2E4EA;
    border-radius: 20px;
    h1{
      color: var(--Gray-700, #344054);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 27px;
    }
    p{
      color: var(--Gray-700, #344054);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 27px; 
    }
  }
  .ModelStyleContainer_support_footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
  }
  .ModelStyleContainer_support_status{
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: flex-end;
    margin-right: 20px;
    margin-bottom: 20px;
    .supportalerts_select {
    background-color: red;
    border: none;
    padding: 8px 4px;
    color: #276ef1;
    background-color: #dae7ff;
    border-radius: 5px;
  }
  }
  .ModelStyleContainer_support_Confirm{
    margin-bottom: 20px;
    button {
      background: #000000;
      font-family: DM Sans;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0px;
      text-align: center;
      color: #ffffff;
      padding: 10px 14px;
      border: none;
      border-radius: 8px;
      cursor: pointer;
    }
  }
`;

export { AlertsContainer, ModelStyleContainer };
