import * as React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { getDataFromCollection, getDriverRides } from '../../utils/firebaseDB';
import { useNavigate } from 'react-router-dom';
import { CustomTableContainer } from './style';
import Loader from 'components/loading/loading';
import Box from '@mui/material/Box';

const columns = [
  { id: 'id', label: 'Driver ID ' },
  { id: 'name', label: 'Driver' },
  { id: 'email', label: 'Email' },
  { id: 'contactNumber', label: 'Mobile Number' },
  { id: 'state', label: 'State' },
  { id: 'totalRider', label: 'Total Rides' },
  { id: 'status', label: 'Status' }
];

export default function StickyHeadTable() {
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(14);
  const [userData, setuserData] = React.useState([]);
  const [driverDataLoading, setdriverDataLoading] = React.useState(true);
  const [search, setSearch] = React.useState('')

  React.useEffect(() => {
    getDataFromCollection('users')
      .then(async (result) => {
        const parsedItems = result
          .filter((item) => item.updatedAt)
          .map((item) => ({
            ...item,
            updatedAt: new Date(item.updatedAt.seconds * 1000 + item.updatedAt.nanoseconds / 1000000)
          }));

        parsedItems.sort((a, b) => b.updatedAt - a.updatedAt);
        const mostRecentItem = parsedItems.length > 0 ? parsedItems : null;

        setdriverDataLoading(false);
        if (mostRecentItem.length) {
          const driverArray = await Promise.all(
            mostRecentItem
              .filter((driver) => driver.userType === 'Driver' && driver.isApproved)
              .map(async (subdriver) => {
                const totalRider = await getDriverRides('rides', subdriver.uid);
                return {
                  id: subdriver.id,
                  uid: subdriver.uid,
                  name: subdriver?.displayName || '---',
                  email: subdriver.email || '---',
                  contactNumber: subdriver.phoneNumber || '---',
                  address: subdriver.city || '---',
                  state: subdriver.state || '---',
                  totalRider: totalRider.length,
                  status: subdriver.isApproved ? 'Active' : 'Not Approved'
                };
              })
          );

          setuserData(driverArray);
        }
      })
      .catch((error) => {
        setdriverDataLoading(false);
        console.error('Error fetching data:', error);
      });
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const rideDetailHandler = (ride) => {
    navigate(`detail/${ride.uid}`);
  };


  const filteredData = userData
  ? userData.filter((client) => {
    if (!search) {
      return {
        ...client
      };
    }
    return (
      client.name.toLowerCase().includes(search.toLowerCase()) ||
      client.state.toLowerCase().includes(search.toLowerCase()) ||
      client.email.toLowerCase().includes(search.toLowerCase()) ||
      client.address?.includes(search) ||
      client.contactNumber?.includes(search) ||
      client.id?.includes(search)
    );
  }).map((clientRecord) => {
    return {
      ...clientRecord,
      name: `${clientRecord.name}`,
    }
  })
  : [];

  
  return (
    <CustomTableContainer>
      <div className="rider_table_filter">
        <h1>Driver</h1>
        <Box>
          <input className="table_search_input" placeholder="Search"  onChange={(e) => setSearch(e.target.value)} />
        </Box>
      </div>

      {driverDataLoading ? (
        <Loader />
      ) : (
        <>
          {' '}
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                      sx={{ cursor: 'pointer' }}
                      onClick={() => rideDetailHandler(row)}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number' ? column.format(value) : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[14, 25, 100]}
            component="div"
            count={userData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </CustomTableContainer>
  );
}
