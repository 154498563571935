/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { getDataFromCollection } from '../../utils/firebaseDB';
import { useNavigate } from 'react-router-dom';
import { CustomTableContainer } from './style';
import Loader from 'components/loading/loading';
import tableLocationPoint from 'assets/shalnyimgages/tableLocationPoint.svg';
import locationDot from 'assets/shalnyimgages/locationDot.svg';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import moment from '../../../node_modules/moment/moment';

const columns = [
  { id: 'id', label: 'Ride ID' },
  { id: 'riderName', label: 'Customer' },
  { id: 'driverName', label: 'Driver' },
  { id: 'pick_up', label: 'Pickup' },
  { id: 'drop_off', label: 'Dropoff' },
  { id: 'pickupDate', label: 'Pickup Date' },
  { id: 'riderFare', label: 'Total Amount', align: 'center' },
  { id: 'status', label: 'Status' },
  {
    id: 'action',
    label: ''
  }
];

export default function StickyHeadTable() {
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(14);
  const [riderData, setriderData] = React.useState([]);
  const [riderDataLoading, setriderDataLoading] = React.useState(true);
  const [selectedStatus, setSelectedStatus] = React.useState();

  React.useEffect(() => {
    getDataFromCollection('rides')
      .then((result) => {
        setriderDataLoading(false);
        if (result.length) {
          const newArr = result.map((rider, index) => {
            return {
              id: index + 1,
              riderName: rider?.passengerData?.displayName || '--',
              driverName: rider?.driverData?.username || '--',
              drop_off: rider.toData.address,
              pick_up: rider.fromData.address,
              pickupDate: moment(rider.placedDate).format('DD/MM/YYYY'),
              riderFare: rider.selectedPrice.toFixed(2),
              status: rider.rideStatus
            };
          });

          setriderData(newArr);
        }
      })
      .catch((error) => {
        setriderDataLoading(false);
        console.error('Error fetching data:', error);
      });
  }, []);

  const handleChange = (event) => {
    setriderDataLoading(true);
    setSelectedStatus(event.target.value);
    getDataFromCollection('rides')
      .then((result) => {
        setriderDataLoading(false);
        if (result.length) {
          const newArr = result
            .map((rider, index) => {
              return {
                id: index + 1,
                riderName: rider?.passengerData?.displayName || '--',
                driverName: rider?.driverData?.username || '--',
                drop_off: rider.toData.address,
                pick_up: rider.fromData.address,
                pickupDate: `${rider.placedDate} ${rider.placedTime}`,
                riderFare: rider.selectedPrice.toFixed(2),
                status: rider.rideStatus
              };
            })
            .filter((riderstatus) => `${riderstatus.status}` === event.target.value);

          setriderData(newArr);
        }
      })
      .catch((error) => {
        setriderDataLoading(false);
        console.error('Error fetching data:', error);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const rideDetailHandler = (ride) => {
    navigate(`detail/${ride.id}`);
  };

  console.log(riderData, 'riderDatariderData');

  return (
    <CustomTableContainer>
      <div className="rider_table_filter">
        <h1>Rides</h1>
        <Box
          sx={{
            display: 'grid',
            gap: 1,
            gridTemplateColumns: 'repeat(2, 1fr)'
          }}
        >
          <input className='table_search_input'  placeholder="Search" />
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Filter</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedStatus}
              label="Filter"
              onChange={handleChange}
            >
              <MenuItem value="Compeleted">Completed</MenuItem>
              <MenuItem value="Cancelled">Cancelled</MenuItem>
              <MenuItem value="Placed">Placed</MenuItem>
              <MenuItem value="OnTheWay">On The Way</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </div>
      {riderDataLoading ? (
        <Loader />
      ) : (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {riderData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                      sx={{ cursor: 'pointer' }}
                      onClick={() => rideDetailHandler(row)}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {value === 'Compeleted' ? (
                              <p className="successTableStatus">Completed</p>
                            ) : value === 'Cancelled' ? (
                              <p className="cancelTableStatus">{row['status']}</p>
                            ) : value === 'Placed' ? (
                              <p className="placesTableStaus">{row['status']}</p>
                            ) : column.id === 'pick_up' ? (
                              <div className="ridesTableLocation">
                                <img width={13} height={13} src={locationDot} alt="tableLocationPoint" />
                                <p>{row['pick_up']}</p>
                              </div>
                            ) : column.id === 'drop_off' ? (
                              <div className="ridesTableLocation">
                                <img src={tableLocationPoint} alt="tableLocationPoint" />
                                <p>{row['drop_off']}</p>
                              </div>
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[14, 25, 100]}
            component="div"
            count={riderData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </CustomTableContainer>
  );
}
