const { axiosBackendApiInstance } = require('./axiosInstance');

export const postRequest = async (endpoint, body) => {
  return axiosBackendApiInstance
    .post(`${endpoint}`, body)
    .then(function (response) {
      return response?.data;
    })
    .catch(function (error) {
      if (error.message === 'Network Error') {
        return {
          status: 500,
          success: false,
          message: error?.message,
          data: {}
        };
      } else if (error.message === 'Request failed with status code 401') {
        localStorage.clear();
        window.location.href = '/';
      } else {
        return error.response?.data;
      }
    });
};
