import React from 'react';
import { CustomerDetailContainer } from './style';
import { useNavigate, useParams } from 'react-router-dom';
import backArrow from 'assets/shalnyimgages/back arrow.svg';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { getCustomerRides, getDocumentDetail } from '../../utils/firebaseDB';
import tableLocationPoint from 'assets/shalnyimgages/tableLocationPoint.svg';
import avatarpng from 'assets/shalnyimgages/avatarpng.png';
import Loader from 'components/loading/loading';

const columns = [
  { id: 'driverName', label: 'Driver' },
  { id: 'contactNumber', label: 'Mobile Number' },
  { id: 'location', label: 'Location' },
  { id: 'truck', label: 'Truck #' },
  { id: 'truckType', label: 'Truck Type' },
  { id: 'action', label: 'Action' }
];

const CustomerDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [riderData, setriderData] = React.useState([]);
  const [page] = React.useState(0);
  const [rowsPerPage] = React.useState(14);
  const [isLoading, setIsLoading] = React.useState(true);
  const [userDetail, setUserDetail] = React.useState({});

  React.useEffect(() => {
    getCustomerRides('rides',id)
      .then((result) => {
        setIsLoading(false);
        if (result.length) {
          const newArr = result.map((rider) => {
            return {
              id: rider.id,
              driverName: rider?.driverData?.username || '--',
              contactNumber: rider?.driverData?.phoneNumber || '--',
              location: [rider.fromData.address, rider.toData.address],
              truck: rider.truck,
              truckType: rider.truckType
            };
          });

          setriderData(newArr);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error('Error fetching data:', error);
      });
  }, [id]);

  React.useEffect(() => {
    (async () => {
      const detail = await getDocumentDetail('users', id);
      setUserDetail(detail);
    })();
  }, [id]);

  console.log(userDetail, 'isLoading', isLoading);

  const detailPageBackHandler = () => {
    navigate(`/customers`);
  };

  return (
    <CustomerDetailContainer>
      {isLoading ? (
        <Loader/>
      ) : (
        <>
          <div className="customerDetailPage_Header">
            <div role="button" tabIndex={0} onClick={detailPageBackHandler} onKeyDown={(e) => console.log(e)}>
              <img src={backArrow} alt="backArrow" />
            </div>
            <h2>Back</h2>
          </div>
          <div className="customerDetailPage_body">
            <div className="customerDetailPage_body_profile">
              <img src={userDetail?.profilePicture ? userDetail?.profilePicture : avatarpng} alt="profile" />
              <h2 className="customerDetailPage_body_profile_status">{userDetail?.isApproved ? 'Active' : 'Disable'}</h2>
            </div>
            <div className="customerDetailPage_body_content">
              <div className="customerDetailPage_body_content_stats">
                <div className="customerDetailPage_body_content_stats_card">
                  <h1>Total Trips Taken</h1>
                  <h2>45</h2>
                </div>
                <div className="customerDetailPage_body_content_stats_card">
                  <h1>Total Trips Taken</h1>
                  <h2>45</h2>
                </div>
                <div className="customerDetailPage_body_content_stats_card">
                  <h1>Total Trips Taken</h1>
                  <h2>45</h2>
                </div>
              </div>
              <div className="customerDetailPage_body_content_personal">
                <h1>Personal detail</h1>
                <div className="customerDetailPage_body_content_personal_col">
                  <div className="customerDetailPage_body_content_personal_col_column">
                    <div className="customerDetailPage_body_content_personal_col_column_row">
                      <h1>Name</h1>
                      <h2>{userDetail.displayName}</h2>
                    </div>
                  </div>
                  <div className="customerDetailPage_body_content_personal_col_column">
                    <div className="customerDetailPage_body_content_personal_col_column_row">
                      <h1>Email</h1>
                      <h2>{userDetail.email}</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="customerDetailPage_body_content_rides">
            <h1>Customer Rides</h1>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {riderData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.id === 'action' ? (
                                <button onClick={() => rideDetailHandler(row)} className="request_detail_handler">
                                  Detail
                                </button>
                              ) : column.id === 'location' ? (
                                <div className="customerDiriverLocation">
                                  <div className="ridesTableLocation">
                                    <img src={tableLocationPoint} alt="tableLocationPoint" />
                                    <p>{row['location'][0]}</p>
                                  </div>
                                  <div className="ridesTableLocation">
                                    <img src={tableLocationPoint} alt="tableLocationPoint" />
                                    <p>{row['location'][1]}</p>
                                  </div>
                                </div>
                              ) : (
                                value
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </>
      )}
    </CustomerDetailContainer>
  );
};

export default CustomerDetail;
