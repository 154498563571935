import * as React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { getDataFromCollection } from '../../utils/firebaseDB';
import { useNavigate } from 'react-router-dom';
import { CustomTableContainer } from './style';
import Loader from 'components/loading/loading';
const columns = [
  { id: 'id', label: 'ID' },
  { id: 'companyName', label: 'Insurances company' },
  { id: 'email', label: 'Email' },
  { id: 'contactNumber', label: 'Mobile Number' },
  { id: 'address', label: 'Address' },
  { id: 'totalCustomers', label: 'Total customer' },
  { id: 'status', label: 'Status' },
  { id: 'action', label: 'Action' }
];

export default function StickyHeadTable() {
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(14);
  const [userData, setuserData] = React.useState([]);
  const [insuraceDataLoading, setinsuraceDataLoading] = React.useState(true);

  const userDetail = localStorage.getItem('userDetail');
  let parseDetail = JSON.parse(userDetail);

  React.useEffect(() => {
    getDataFromCollection('insuranceComapanies')
      .then((result) => {
        setinsuraceDataLoading(false);
        const filteredCompany = result.filter((company) => company.user === parseDetail.uid);
        setuserData(filteredCompany);
      })
      .catch((error) => {
        setinsuraceDataLoading(false);
        console.error('Error fetching data:', error);
      });
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const rideDetailHandler = (ride) => {
    navigate(`detail/${ride.id}`);
  };
  const addCompanyHandler = () => {
    navigate('/new-insurance-companies');
  };

  return (
    <CustomTableContainer>
      <div className="company_header">
        <h1>Insurance companies</h1>
        <button onClick={addCompanyHandler}>Add Company</button>
      </div>
      {insuraceDataLoading ? (
        <Loader />
      ) : (
        <>
          {' '}
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {userData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.id === 'id' ? (
                              i + 1
                            ) : column.id === 'action' ? (
                              <button className="request_detail_handler" onClick={() => rideDetailHandler(row)}>
                                Detail
                              </button>
                            ) : column.format && typeof value === 'number' ? (
                              column.format(value)
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[14, 25, 100]}
            component="div"
            count={userData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </CustomTableContainer>
  );
}
