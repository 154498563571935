import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/InsuranceLayout';
import ProtectedRoute from 'utils/insurance_company_protectedRoutes';

const DashboardDefault = Loadable(lazy(() => import('pages/insuranceComapanies/dashboard')));
const CompanyCustomers = Loadable(lazy(() => import('pages/insuranceComapanies/company-customer')));
const CompanyAddCustomers = Loadable(lazy(() => import('pages/insuranceComapanies/company-customer/add-customer')));
const CompnayRides = Loadable(lazy(() => import('pages/insuranceComapanies/company-rides')));

const SamplePage = Loadable(lazy(() => import('pages/extra-pages/SamplePage')));

const InsuranceCompanyRoutes = {
  path: '/',
  element: (
    <ProtectedRoute>
      <MainLayout></MainLayout>
    </ProtectedRoute>
  ),
  children: [
    {
      path: 'company_dashbaord',
      element: <DashboardDefault />
    },
    {
      path: 'company_rides',
      element: <CompnayRides />
    },
    {
      path: 'company_customer',
      element: <CompanyCustomers />
    },
    {
      path: 'company_add_customer',
      element: <CompanyAddCustomers />
    },
    {
      path: '*',
      element: <SamplePage />
    }
  ]
};

export default InsuranceCompanyRoutes;
