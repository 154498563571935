import React from 'react';
import { useRoutes, useLocation, useNavigate } from 'react-router-dom';

// project import
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import InsuranceCompanyRoutes from './InsuranceCompanyRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // const shlny_admin = localStorage.getItem('shlny_admin_token');
  // const shlny_company = localStorage.getItem('shlny_company_token');

  React.useEffect(() => {
    if (pathname === '/') {
      navigate('/dashboard');
    }
  }, [pathname]);

  return useRoutes([MainRoutes, LoginRoutes, InsuranceCompanyRoutes]);
}
