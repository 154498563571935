import * as React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { getDataFromCollection } from '../../utils/firebaseDB';
import tableDots from 'assets/shalnyimgages/table-dots.svg';
import { useNavigate } from 'react-router-dom';
import { CustomTableContainer } from './style';
import Loader from 'components/loading/loading';

const columns = [
  { id: 'id', label: 'Customer ID' },
  { id: 'customerName', label: 'Customer' },
  { id: 'email', label: 'Email' },
  { id: 'contactNumber', label: 'Mobile Number' },
  { id: 'city', label: 'City' },
  { id: 'joiningDate', label: 'Joining Date' },
  { id: 'action', label: '' }
];

export default function StickyHeadTable() {
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(14);
  const [userData, setuserData] = React.useState([]);
  const [customerLoading, setcustomerLoading] = React.useState(true);

  React.useEffect(() => {
    getDataFromCollection('users')
      .then((result) => {
        setcustomerLoading(false);
        let customerArray = result
          .filter((cus) => cus.userType === 'Rider')
          .map((user) => {
            return {
              id : user.id,
              customerName: user.displayName || '--',
              email: user.email || '--',
              contactNumber: user.phoneNumber || '--',
              joiningDate: user.joiningDate || '--',
              city: user.city || '--',
            };
          });
        setuserData(customerArray);
      })
      .catch((error) => {
        setcustomerLoading(false);
        console.error('Error fetching data:', error);
      });
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const rideDetailHandler = (ride) => {
    navigate(`/customer/detail/${ride.id}`);
  };

  return (
    <CustomTableContainer>
      <h1>Customer</h1>
      {customerLoading ? (
        <Loader />
      ) : (
        <>
          {' '}
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {userData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.id === 'id' ? (
                              i + 1
                            ) : column.id === 'action' ? (
                              <div role="button" tabIndex={0} onKeyDown={(e) => console.log(e)} onClick={() => rideDetailHandler(row)} >
                                <img src={tableDots} alt="tableDots" />
                              </div>
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[14, 25, 100]}
            component="div"
            count={userData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </CustomTableContainer>
  );
}
