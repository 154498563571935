import * as React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { getDataFromCollection } from '../../utils/firebaseDB';
import { CustomTableContainer } from './style';
import Loader from 'components/loading/loading';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Box from '@mui/material/Box';
const columns = [
  { id: 'id', label: 'Request ID ' },
  { id: 'driverId', label: 'Driver ID' },
  { id: 'name', label: 'Driver' },
  { id: 'createdAt', label: 'Request Date' },
  { id: 'status', label: 'Status' }
];

export default function StickyHeadTable() {
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(14);
  const [userData, setuserData] = React.useState([]);
  const [driverRequestLoading, setdriverRequestLoading] = React.useState(true);
  const [search, setSearch] = React.useState('');

  React.useEffect(() => {
    getDataFromCollection('users')
      .then((result) => {
        const parsedItems = result
          .filter((item) => item.updatedAt)
          .map((item) => ({
            ...item,
            updatedAt: new Date(item.updatedAt.seconds * 1000 + item.updatedAt.nanoseconds / 1000000)
          }));

        parsedItems.sort((a, b) => b.updatedAt - a.updatedAt);
        const mostRecentItem = parsedItems.length > 0 ? parsedItems : null;

        setdriverRequestLoading(false);
        if (result.length) {
          const driverArray = mostRecentItem
            .filter(
              (driver) =>
                driver.userType === 'Driver' &&
                !driver.isApproved &&
                (driver?.bankStatus === 'Pending' ||
                  driver?.idStatus === 'Pending' ||
                  driver?.licenseStatus === 'Pending' ||
                  driver?.vehicleStatus === 'Pending')
            )
            .map((subdriver) => {
              return {
                uid: subdriver.uid,
                driverId: subdriver.id,
                name: subdriver?.displayName || '---',
                email: subdriver.email || '---',
                contactNumber: subdriver.phoneNumber || '---',
                address: subdriver.city || '---',
                state: subdriver.state || '---',
                createdAt: moment(subdriver.updatedAtDate).format('DD/MM/YYYY'),
                status: subdriver.isApproved ? 'Active' : 'Not Approved'
              };
            });
          // Sort the data based on the createdAt property
          // .sort((a, b) => moment(b.createdAt, 'DD/MM/YYYY').valueOf() - moment(a.createdAt, 'DD/MM/YYYY').valueOf());

          setuserData(driverArray);
        }
      })
      .catch((error) => {
        setdriverRequestLoading(false);
        console.error('Error fetching data:', error);
      });
  }, []);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const driverDetailHandler = (ride) => {
    navigate(`request/${ride.uid}`);
  };

  console.log(userData, 'userData');
  const filteredData = userData
    ? userData
        .filter((client) => {
          if (!search) {
            return {
              ...client
            };
          }
          return client.driverId.toLowerCase().includes(search.toLowerCase()) || client.name.toLowerCase().includes(search.toLowerCase());
        })
        .map((clientRecord) => {
          return {
            ...clientRecord,
            name: `${clientRecord.name} `
          };
        })
    : [];

  return (
    <CustomTableContainer>
      <div className="rider_table_filter">
        <h1>Drivers Verification</h1>
        <Box>
          <input className="table_search_input" placeholder="Search" onChange={(e) => setSearch(e.target.value)} />
        </Box>
      </div>

      {driverRequestLoading ? (
        <Loader />
      ) : (
        <>
          {' '}
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                      sx={{ cursor: 'pointer' }}
                      onClick={() => driverDetailHandler(row)}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.id === 'id' ? i + 1 : column.format && typeof value === 'number' ? column.format(value) : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[14, 25, 100]}
            component="div"
            count={userData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </CustomTableContainer>
  );
}
