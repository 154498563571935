import React, { useState } from 'react';
import { CompanyDetailPage } from './style';

import backArrow from 'assets/shalnyimgages/back arrow.svg';
import { Box, Tab, Tabs } from '@mui/material';
import { SettingOutlined, UserOutlined } from '@ant-design/icons';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from 'components/loading/loading';
import TableHead from '@mui/material/TableHead';

import TableRow from '@mui/material/TableRow';
import { getDataFromCollection, getDocumentDetail } from '../../utils/firebaseDB';
import tableDots from 'assets/shalnyimgages/table-dots.svg';

const columns = [
  { id: 'id', label: 'ID' },
  { id: 'customerName', label: 'Customer' },
  { id: 'email', label: 'Email' },
  { id: 'contactNumber', label: 'Mobile Number' },
  { id: 'joiningDate', label: 'Joining Data' },
  { id: 'status', label: 'Status' }
];
const CompanyDetail = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { id } = useParams();
  const [value, setValue] = useState(0);
  const [userData, setuserData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(14);
  const [companyDetail, setcompanyDetail] = useState({});
  const [companyDetialLoading, setcompanyDetialLoading] = useState(true);

  React.useEffect(() => {
    getDataFromCollection('users')
      .then((result) => {
        let customerArray = result
          .filter((cus) => cus.userType === 'insuredUser')
          .map((user) => {
            return {
              customerName: user.customerName || 'John Doe',
              email: user.customerEmail || 'John@gmail.com',
              vehicleName: user.vehicleName || 'John@gmail.com',
              contactNumber: user.customerContact || '1234567890',
              joiningDate: '23 Sept, 2023',
              status: 'Active'
            };
          });
        setuserData(customerArray);
      })
      .catch((error) => {
        // setriderDataLoading(false);
        console.error('Error fetching data:', error);
      });
  }, []);

  React.useEffect(() => {
    (async () => {
      const detail = await getDocumentDetail('insuranceComapanies', id);
      setcompanyDetialLoading(false);
      setcompanyDetail(detail);
    })();
  }, [id]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const detailPageBackHandler = () => {
    navigate(`/insurance-companies`);
  };

  function TabPanel({ children, value, index, ...other }) {
    return (
      <div role="tabpanel" hidden={value !== index} id={`profile-tabpanel-${index}`} aria-labelledby={`profile-tab-${index}`} {...other}>
        {value === index && children}
      </div>
    );
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `profile-tab-${index}`,
      'aria-controls': `profile-tabpanel-${index}`
    };
  }

  if (companyDetialLoading) {
    return <Loader />;
  }

  return (
    <CompanyDetailPage>
      <div className="detail_page_header_back">
        <div role="button" tabIndex={0} onClick={detailPageBackHandler} onKeyDown={(e) => console.log(e)}>
          <img src={backArrow} alt="backArrow" />
        </div>
        <h2>Back</h2>
      </div>
      <div className="insurence_company_detail">
        <h1>Insurance Company</h1>
        <div className="insurence_company_detail_content">
          <div className="insurence_company_detail_content_data">
            <h1>Name:</h1>
            <p>{companyDetail?.companyName}</p>
          </div>
          <div className="insurence_company_detail_content_data">
            <h1>Gmail:</h1>
            <p>{companyDetail?.email}</p>
          </div>
          <div className="insurence_company_detail_content_data">
            <h1>Mobile Number:</h1>
            <p>{companyDetail?.contactNumber}</p>
          </div>
          <div className="insurence_company_detail_content_data">
            <h1>Address:</h1>
            <p>{companyDetail?.address}</p>
          </div>
        </div>
      </div>
      <div className="insurence_company_detail">
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs variant="fullWidth" value={value} onChange={handleChange} aria-label="profile tabs">
            <Tab
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                textTransform: 'capitalize'
              }}
              icon={<SettingOutlined style={{ marginBottom: 0, marginRight: '10px' }} />}
              label="Customers"
              {...a11yProps(1)}
            />
            <Tab
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                textTransform: 'capitalize'
              }}
              icon={<UserOutlined style={{ marginBottom: 0, marginRight: '10px' }} />}
              label="Scheduled Ride"
              {...a11yProps(0)}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0} dir={theme.direction}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {userData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.id === 'id' ? (
                            i + 1
                          ) : column.id === 'action' ? (
                            <div role="button" tabIndex={0} onClick={() => rideDetailHandler(row)} onKeyDown={(e) => console.log(e)}>
                              <img src={tableDots} alt="tableDots" />
                            </div>
                          ) : column.format && typeof value === 'number' ? (
                            column.format(value)
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[14, 25, 100]}
            component="div"
            count={userData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <h1>one</h1>
        </TabPanel>
      </div>
    </CompanyDetailPage>
  );
};

export default CompanyDetail;
