// // material-ui
// import { Box } from '@mui/material';

// // project import
// import NavGroup from './NavGroup';
// import menuItem from 'menu-items';

// // ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

// const Navigation = () => {
//   const getInuranceCompanyRotes = menuItem.items.filter((routeId) => routeId.id === 'utilities');

//   const navGroups = getInuranceCompanyRotes.map((item) => {
//     return <NavGroup key={item.id} item={item} />;
//     // switch (item.type) {
//     //   case 'group':
//     //     return <NavGroup key={item.id} item={item} />;
//     //   default:
//     //     return (
//     //       <Typography key={item.id} variant="h6" color="error" align="center">
//     //         Fix - Navigation Group
//     //       </Typography>
//     //     );
//     // }
//   });

//   return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
// };

// export default Navigation;

import React, { useState, useEffect, useCallback } from 'react';
import { getDetails } from '../../../../../utils/firebaseDB';
// material-ui
import { Box } from '@mui/material';

// project import
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import Loader from 'components/Loader';
// import { useNavigate } from 'react-router-dom';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  const getInuranceCompanyRotes = menuItem.items.filter((routeId) => routeId.id === 'utilities');
  const selectedId = localStorage.getItem('shlny_user_uid');
  // const navigate = useNavigate();
  const [manageTabs, setManageTabs] = useState([]);
  const [isLoading, setisLoading] = useState(true)

  const fetchUserDetail = useCallback(async (selectedId) => {
    let result = await getDetails('panelUser', selectedId);
    const updatedData = getInuranceCompanyRotes.map((item) => {
      const filteredChildren = item.children.filter((child) => result.panelAccess?.includes(child.id));
      // const dashboardItem = item.children.find((child) => child.id === 'dashboard');
      // const mergedChildren = dashboardItem ? [dashboardItem, ...filteredChildren] : filteredChildren;
      const mergedChildren = filteredChildren;
      return { ...item, children: mergedChildren };
    });

    // let firstChild = result.panelAccess[0]
    // navigate(`${firstChild.toLowerCase()}`);
    setManageTabs(updatedData);
    setisLoading(false)
  }, []);
  useEffect(() => {
    fetchUserDetail(selectedId);
  }, [selectedId]);

  if(isLoading) {
    return <Loader/>
  }

  const navGroups = manageTabs.map((item) => {
    return <NavGroup key={item.id} item={item} />;
  });

  return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

export default Navigation;
