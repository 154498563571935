import styled from 'styled-components';

const AlertsContainer = styled.div`
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 30px 0px rgba(47, 45, 107, 0.1);
  padding: 25px 13px;
  .alertHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    h1 {
      font-family: DM Sans;
      font-size: 24px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0em;
      margin-bottom: 20p;
    }

    button {
      background: #000000;
      font-family: DM Sans;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0px;
      text-align: center;
      color: #ffffff;
      padding: 10px 14px;
      border: none;
      border-radius: 8px;
      cursor: pointer;
    }
  }
  .textellipses {
    display: block;
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .action_button {
    border: 1px solid #000000;
    background: #ffffff;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.20000000298023224px;
    text-align: center;
    border-radius: 7px;
    cursor: pointer;
  }
  .supportalerts_select {
    border: none;
    padding: 8px 4px;
    color: #276ef1;
    background-color: #f0f8f2;
    border-radius: 5px;
  }
  .table_search_input{
    width: 100%;
    border: 1px solid #B6B6B6;
    border-radius: 5px;
    padding: 10px;
    outline:  none;
    color: #B6B6B6;
    font-size: 16px;
  }
`;

const ModelStyleContainer = styled.div`
  .ModelStyleContainer_header {
    border-radius: 8px 8px 0px 0px;
    border-bottom: 1px solid var(--Gray-300, #d0d5dd);
    background: #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    h1 {
      color: var(--Gray-700, #344054);

      /* Text/Text lg/Semibold */
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 34px; /* 188.889% */
    }
    &_crossbutton {
      font-size: 20px;
      cursor: pointer;
    }
  }
  .ModelStyleContainer_body {
    padding: 30px;

    &_content_personal_col {
      display: flex;
      align-items: center;
      gap: 30px;

      &_row {
        width: 100%;
        p {
          color: #838181;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          margin-bottom: 5px;
        }
        input {
          border-radius: 4px;
          border: 1px solid #d9d9d9;
          padding: 10px 10px;
          width: 100%;
          outline: none;
        }
      }
    }
  }

  .ModelStyleContainer_body_pageAccess {
    margin-top: 30px;
    &_checks{
        display: flex;
    }
    h1 {
      color: #838181;
      font-family: DM Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }
    .information_container_check {
      display: flex;
      align-self: start;
      align-items: center;
      gap: 20px;
      h1 {
        color: var(--Gray-600, #475467);
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 166.667% */
      }
    }
  }

  .alertformactionbutton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    margin-top: 30px;
    &_cancel {
      background: #ffffff;
      font-family: DM Sans;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0px;
      text-align: center;
      color: #000000;
      padding: 10px 14px;
      border: 1px solid black;
      border-radius: 8px;
      cursor: pointer;
    }
    &_invite {
      background: #000000;
      font-family: DM Sans;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0px;
      text-align: center;
      color: #ffffff;
      padding: 10px 14px;
      border: none;
      border-radius: 8px;
      cursor: pointer;
    }
  }
`;

export { AlertsContainer, ModelStyleContainer };
