// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,200;9..40,300;9..40,400;9..40,500;9..40,600;9..40,700;9..40,800;9..40,900;9..40,1000&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*::before:active {
  box-sizing: border-box;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
.recentriders_table {
  padding: 28px 13px;
}
.dashboardGridMainHeading {
  font-family: DM Sans;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.4;
  margin-bottom: 10px;
}
.placed{
    color: red;
}
.disableButton{
  cursor: not-allowed;
  background-color: rgb(229, 229, 229) !important;
  pointer-events:none;
}

.react-viewer-close.react-viewer-btn{
  z-index: 9999 !important;
}`, "",{"version":3,"sources":["webpack://./src/style.css"],"names":[],"mappings":"AAEA;EACE,sBAAsB;AACxB;;AAEA;;;;;;;EAOE,SAAS;EACT,UAAU;AACZ;AACA;EACE,kBAAkB;AACpB;AACA;EACE,oBAAoB;EACpB,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;IACI,UAAU;AACd;AACA;EACE,mBAAmB;EACnB,+CAA+C;EAC/C,mBAAmB;AACrB;;AAEA;EACE,wBAAwB;AAC1B","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,200;9..40,300;9..40,400;9..40,500;9..40,600;9..40,700;9..40,800;9..40,900;9..40,1000&display=swap');\n\n*::before:active {\n  box-sizing: border-box;\n}\n\np,\nh1,\nh2,\nh3,\nh4,\nh5,\nh6 {\n  margin: 0;\n  padding: 0;\n}\n.recentriders_table {\n  padding: 28px 13px;\n}\n.dashboardGridMainHeading {\n  font-family: DM Sans;\n  font-weight: 600;\n  font-size: 1.25rem;\n  line-height: 1.4;\n  margin-bottom: 10px;\n}\n.placed{\n    color: red;\n}\n.disableButton{\n  cursor: not-allowed;\n  background-color: rgb(229, 229, 229) !important;\n  pointer-events:none;\n}\n\n.react-viewer-close.react-viewer-btn{\n  z-index: 9999 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
