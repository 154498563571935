export const rejectionReasons = [
  {
    reasonType: 'Bank_Information',
    reasonValues: {
      Bank_Location: [ 'Incorrect', 'Invalid'],
      Bank_Name: [ 'Incorrect', 'Invalid'],
      Account_Holder_Name: [ 'Incorrect', 'Invalid'],
      Account_Number: [ 'Incorrect', 'Invalid'],
      IBAN_Number: [ 'Incorrect', 'Invalid'],
      IBAN_Certificate: [ 'Incorrect', 'Invalid'],
      SWIFT_Code: [ 'Incorrect', 'Invalid'],
      Bank_Currency: [ 'Incorrect', 'Invalid'],
    }
  },
  {
    reasonType: 'Vehicle_Detail',
    reasonValues: {
      T_C_Number: [ 'Incorrect', 'Invalid'],
      Model: [ 'Incorrect', 'Invalid'],
      Year_of_expiry: [ 'Incorrect', 'Already Expired', 'Invalid'],
      Manufacturer: [ 'Incorrect', 'Invalid'],
      Truck_Type: [ 'Incorrect', 'Invalid'],
      Color: [ 'Incorrect', 'Invalid'],
      Plate: [ 'Incorrect', 'Invalid'],
      Plate_Source: [ 'Incorrect', 'Invalid'],
      Plate_Number: [ 'Incorrect', 'Invalid'],
      Plate_Kind :[ 'Incorrect', 'Invalid'],
      Card_Front: [
        
        'Incorrect',
        'Blurry/Low Resolution Image',
        'Invalid Card Type',
        'Incomplete Image',
        'Duplicated Information',
        'Invalid'
      ],
      Card_Back: [
        
        'Incorrect',
        'Blurry/Low Resolution Image',
        'Invalid Card Type',
        'Incomplete Image',
        'Duplicated Information',
        'Invalid'
      ],
    }
  },
  {
    reasonType: 'Driving_License',
    reasonValues: {
      Licence_Number: [ 'Incorrect', 'Duplicated Information', 'Invalid'],
      Front_Card_Image: [
        
        'Incorrect',
        'Blurry/Low Resolution Image',
        'Invalid Card Type',
        'Incomplete Image',
        'Duplicated Information',
        'Invalid'
      ],
      Back_Card_Image: [
        
        'Incorrect',
        'Blurry/Low Resolution Image',
        'Invalid Card Type',
        'Incomplete Image',
        'Duplicated Information',
        'Invalid'
      ],
      Date_of_expiry: [ 'Incorrect', 'Already Expired', 'Invalid']
    }
  },
  {
    reasonType: 'Identification',
    reasonValues: {
      Licence_Number: [ 'Incorrect', 'Duplicated Information', 'Invalid'],
      Front_Card_Image: [
        
        'Incorrect',
        'Blury/Low Resoluation Image',
        'Invalid Card Type',
        'Incomplete Image',
        'Duplicated Information',
        'Invalid'
      ],
      Back_Card_Image: [
        
        'Incorrect',
        'Blury/Low Resoluation Image',
        'Invalid Card Type',
        'Incomplete Image',
        'Duplicated Information',
        'Invalid'
      ],
      Date_of_expiry: [ 'Incorrect', 'Already Expired', 'Invalid']
    }
  }
];
