
import dashbaord_icon from 'assets/shalnyimgages/dashbaord_icon.svg';
import alert_icon from 'assets/shalnyimgages/alert_icon.svg';
import customer_icon from 'assets/shalnyimgages/customer_icon.svg';
import dirverVerification_icon from 'assets/shalnyimgages/dirverVerification_icon.svg';
import driver_icon from 'assets/shalnyimgages/driver_icon.svg';
import rides_icon from 'assets/shalnyimgages/rides_icon.svg';
// import scoreBoard_icon from 'assets/shalnyimgages/scoreBoard_icon.svg';
import support_icon from 'assets/shalnyimgages/support_icon.svg';
import panelManagment_icon from 'assets/shalnyimgages/panelManagment_icon.svg';




// ==============================|| MENU ITEMS - UTILITIES ||============================== //

const utilities = {
  id: 'utilities',
  title: '',
  type: '',

  children: [
    {
      id: 'Dashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/dashboard',
      icon: dashbaord_icon
    },
    {
      id: 'Rides',
      title: 'Rides',
      type: 'item',
      url: 'rides',
      icon: rides_icon
    },
    {
      id: 'Customers',
      title: 'Customers',
      type: 'item',
      url: 'customers',
      icon: customer_icon
    },
    {
      id: 'Drivers',
      title: 'Drivers',
      type: 'item',
      url: 'drivers',
      icon: driver_icon
    },
    {
      id: 'Driver_Requests',
      title: 'Drivers Verification',
      type: 'item',
      url: 'driver_requests',
      icon: dirverVerification_icon
    },
 
    {
      id: 'Notification_Alerts',
      title: 'Alerts',
      type: 'item',
      url: 'notificatin_alerts',
      icon: alert_icon
    },
    {
      id: 'Support_Requests',
      title: 'Support Requests',
      type: 'item',
      url: 'support_requests',
      icon: support_icon
    },
    {
      id: 'Panel_management',
      title: 'Panel Management',
      type: 'item',
      url: 'panel_management',
      icon: panelManagment_icon
    },
    
  
    // {
    //   id: 'vehicle_type',
    //   title: 'Vehicle Type',
    //   type: 'item',
    //   url: 'vahicle-table',
    //   icon: icons.AntDesignOutlined
    // },
    // {
    //   id: 'insurance-companies',
    //   title: 'Insurance Companies',
    //   type: 'item',
    //   url: 'insurance-companies',
    //   icon: icons.AntDesignOutlined
    // }
    // {
    //   id: 'setting',
    //   title: 'Setting',
    //   type: 'item',
    //   url: '#',
    //   icon: icons.AntDesignOutlined
    // },
    // {
    //   id: 'support',
    //   title: 'Support',
    //   type: 'item',
    //   url: '#',
    //   icon: icons.AntDesignOutlined
    // }
  ]
};

export default utilities;
