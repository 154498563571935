import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertsContainer } from './style';
import Loader from 'components/loading/loading';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { getDataFromCollection } from 'utils/firebaseDB';

const columns = [
  { id: 'id', label: 'Notification ID' },
  { id: 'description', label: 'Description' },
  { id: 'createdAt', label: 'Date Created' },
  { id: 'alertOption', label: 'Assign' }
];

const Alerts = () => {
  const navigate = useNavigate();

  const [alertsData, setAlertData] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(14);

  useEffect(() => {
    getDataFromCollection('alerts')
      .then((result) => {
        setisLoading(false);
        setAlertData(result);
      })
      .catch((error) => {
        setisLoading(false);
        console.error('Error fetching data:', error);
      });
  }, []);

  const addNewAlertHandler = () => {
    navigate(`/addNew`);
  };

  const viewAlertHandler = (item) => {

    navigate(`/addNew?alertId=${item.id}`);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <AlertsContainer>
      <div className="alertHeader">
        <h1>Alert User</h1>
        <button onClick={addNewAlertHandler}>+ Add New</button>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {alertsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row , i) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code} onClick={() => viewAlertHandler(row)}  >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.id === 'id' ? i + 1 : column.id === 'description' ? <p className="textellipses">{value}</p> : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[14, 25, 100]}
            component="div"
            count={alertsData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </AlertsContainer>
  );
};

export default Alerts;
