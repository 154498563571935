/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useCallback } from 'react';
import { DriverDetailPageContainer, ModelRejection } from './style';
import backArrow from 'assets/shalnyimgages/back arrow.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { getDocumentDetail, getDetails, getCustomerRides, updateCollectionWithData } from '../../utils/firebaseDB';
import { styled } from '@mui/material/styles';
import Loader from 'components/loading/loading';
import avatarpng from 'assets/shalnyimgages/avatarpng.png';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import noImage from 'assets/shalnyimgages/noImage.png';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import Modal from '@mui/material/Modal';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ratingStar from 'assets/shalnyimgages/star-7207.svg';
import { rejectionReasons } from '../driverRequest/rejectionReasons';
import Viewer from 'react-viewer';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const StyledTabs = styled((props) => <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />)({
  '& .MuiTabs-indicator': {
    backgroundColor: 'black'
  }
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  color: 'balck',
  '&.Mui-selected': {
    color: 'black',
    fontWeight: '900'
  }
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  boxShadow: 24
};

const DriverDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [rejectModal, setRejectModal] = useState(false);
  const [driverDetail, setDriverDetail] = useState(null);
  const [vehicleData, setVehicleData] = useState({});
  const [bankData, setBankData] = useState({});
  const [driverDocuments, setDriverDocuments] = useState({});
  const [driverCompletedRides, setDriverCompletedRides] = useState(0);
  const [driverCancelledRides, setDriverCancelledRides] = useState(0);
  const [value, setValue] = useState(0);
  const [rejectionType, setRejectionType] = useState('Vehicle_Detail');
  const [selectedReason, setselectedReason] = useState('');
  const [buttonDisable, setButtonDisable] = useState(true);
  const [formError, setformError] = useState('');
  const [reasonValues, setReasonValues] = useState({});
  const [isFormSubmit, setisFormSubmit] = useState(false);
  const [identificationReason, setIdentificationReason] = useState({});
  const [vehicalDetailReason, setVehicalDetailReason] = useState({});
  const [bankInformationReason, setBankInformationReason] = useState({});
  const [drivingLicenceReason, setDrivingLicenceReason] = useState({});
  const [previewImage, setPreviewImage] = useState('');
  const [visible, setVisible] = React.useState(false);

  let activeReasons = rejectionReasons.find((detail) => detail.reasonType === rejectionType);
  let vehicleActiveReasons = rejectionReasons.find((detail) => detail.reasonType === 'Vehicle_Detail');
  let identificatinActiveReasons = rejectionReasons.find((detail) => detail.reasonType === 'Identification');
  let bankInformationActiveReasons = rejectionReasons.find((detail) => detail.reasonType === 'Bank_Information');
  let drivingLicenseActiveReasons = rejectionReasons.find((detail) => detail.reasonType === 'Driving_License');

  const handleClose = () => {
    setRejectModal(false), setformError('');
    setPreviewImageModal(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    (async () => {
      const detail = await getDocumentDetail('users', id);
      const verhicleDetail = await getDetails('vehicle', id);
      const bankDetail = await getDetails('banks', id);
      const driverDocumentsDetail = await getDetails('documents', id);
      const driverRidesDetail = await getCustomerRides('rides', id);
      setDriverDetail(detail);
      setVehicleData(verhicleDetail);
      setBankData(bankDetail);
      setDriverDocuments(driverDocumentsDetail);
      setDriverCompletedRides(driverRidesDetail.filter((ride) => ride.rideStatus === 'Compeleted').length);
      setDriverCancelledRides(driverRidesDetail.filter((ride) => ride.rideStatus !== 'Compeleted').length);
    })();
  }, [id]);

  function checkReasonValues(targetObject, validationObject, reasonType) {
    const reasonValues = targetObject.reasonValues;
    const targetReasonType = targetObject.reasonType;

    // Check if the target reasonType matches the one provided
    if (reasonType && targetReasonType !== reasonType) {
      return false;
    }

    for (const key in validationObject) {
      if (key !== 'reasonType' && key in validationObject) {
        const value = validationObject[key];
        if (key in reasonValues && reasonValues[key].includes(value)) {
          return true;
        }
      }
    }
    return false;
  }

  const rejectDetailHandler = async () => {
    if (rejectionType === 'Identification' && checkReasonValues(activeReasons, reasonValues, rejectionType)) {
      setIdentificationReason(reasonValues);
      setRejectModal(false);
    } else if (rejectionType === 'Vehicle_Detail' && checkReasonValues(activeReasons, reasonValues, rejectionType)) {
      setVehicalDetailReason(reasonValues);
      setRejectModal(false);
    } else if (rejectionType === 'Bank_Information' && checkReasonValues(activeReasons, reasonValues, rejectionType)) {
      setBankInformationReason(reasonValues);
      setRejectModal(false);
    } else if (rejectionType === 'Driving_License' && checkReasonValues(activeReasons, reasonValues, rejectionType)) {
      setDrivingLicenceReason(reasonValues);
      setRejectModal(false);
    } else {
      setformError('Please select one rejection reason at least');
    }
  };

  const detailPageBackHandler = () => {
    navigate(`/drivers`);
  };

  const rejectionReasonHandler = (value) => {
    setselectedReason(value);
    setRejectionType(value);
    setButtonDisable(false);
    setRejectModal(true);
    // if (event.target.checked) {
    //   setButtonDisable(false);
    //   let selectedReasonType = event.target.name;
    //   setselectedReason(selectedReasonType);
    //   setRejectionType(selectedReasonType);
    // } else {
    //   setselectedReason('');
    //   setButtonDisable(true);
    // }
  };

  const reasonSelectHandler = (event, reasonKey) => {
    setformError('');
    const newValue = event.target.value;
    // Update the reasonValues state with the new value
    setReasonValues((prevState) => ({
      ...prevState,
      [reasonKey]: newValue
    }));
  };
  const rejectDriverHandler = async () => {
    setisFormSubmit(true);

    if (checkReasonValues(vehicleActiveReasons, vehicalDetailReason, 'Vehicle_Detail')) {
      await Promise.all([
        updateCollectionWithData('vehicle', vehicleData.uid, 'Vehicle_Detail', vehicalDetailReason),
        updateCollectionWithData('vehicle', vehicleData.uid, 'vehicleStatus', 'Cancelled')
      ]);
    }
    if (checkReasonValues(identificatinActiveReasons, identificationReason, 'Identification')) {
      await Promise.all([
        updateCollectionWithData('documents', driverDocuments.uid, 'Identification', identificationReason),
        updateCollectionWithData('documents', driverDocuments.uid, 'idStatus', 'Cancelled')
      ]);
    }
    if (checkReasonValues(drivingLicenseActiveReasons, drivingLicenceReason, 'Driving_License')) {
      await Promise.all([
        updateCollectionWithData('documents', driverDocuments.uid, 'Driving_License', drivingLicenceReason),
        updateCollectionWithData('documents', driverDocuments.uid, 'licenseStatus', 'Cancelled')
      ]);
    }
    if (checkReasonValues(bankInformationActiveReasons, bankInformationReason, 'Bank_Information')) {
      await Promise.all([
        updateCollectionWithData('banks', bankData.uid, 'Bank_Information', bankInformationReason),
        updateCollectionWithData('banks', bankData.uid, 'bankStatus', 'Cancelled')
      ]);
    }
    const promises = [getDetails('banks', id), getDetails('documents', id), getDetails('vehicle', id)];
    Promise.all(promises)
      .then(async (results) => {
        setisFormSubmit(false);
        if (
          results[0].bankStatus === 'Cancelled' ||
          results[1].idStatus === 'Cancelled' ||
          results[1].licenseStatus === 'Cancelled' ||
          results[2].vehicleStatus === 'Cancelled'
        ) {
          await updateCollectionWithData('users', id, 'isApproved', false);

          navigate(`/drivers`);
        } else {
          console.log(
            results[0].bankStatus,
            results[1].idStatus,
            results[1].licenseStatus,
            results[2].vehicleStatus,
            'results[2].vehicleStatus'
          );
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };
  const resetSelectedReason = (rejectionValue) => {
    if (rejectionValue == 'Vehicle_Detail') {
      setVehicalDetailReason({});
      setReasonValues({});
    } else if (rejectionValue == 'Identification') {
      setIdentificationReason({});
      setReasonValues({});
    } else if (rejectionValue == 'Bank_Information') {
      setBankInformationReason({});
      setReasonValues({});
    } else if (rejectionValue == 'Driving_License') {
      setDrivingLicenceReason({});
      setReasonValues({});
    }
  };

  function deepCopy(obj) {
    if (obj === null || typeof obj !== 'object') {
      return obj;
    }

    let copy = Array.isArray(obj) ? [] : {};

    for (let key in obj) {
      if (Object.hasOwnProperty.call(obj, key)) {
        copy[key] = deepCopy(obj[key]);
      }
    }

    return copy;
  }
  const previewImageModalHandler = useCallback((img) => {
    setPreviewImage(img);
    setVisible(true);
  }, []);

  let copiedReasons = deepCopy(activeReasons);

  if (bankData?.bankLocation == 'Inside UAE') {
    delete copiedReasons?.reasonValues.SWIFT_Code;
    delete copiedReasons?.reasonValues.Bank_Currency;
  }

  return (
    <DriverDetailPageContainer>
      {!driverDetail ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <div className="detail_page_header">
            <div
              className="detail_page_header_back"
              role="button"
              tabIndex={0}
              onKeyDown={(e) => console.log(e)}
              onClick={detailPageBackHandler}
            >
              <img src={backArrow} alt="backArrow" />
              <h2>Back</h2>
            </div>
          </div>
          <div className="customerDetailPage_body">
            <div className="customerDetailPage_body_profile">
              <img src={driverDetail?.profilePic ? driverDetail?.profilePic : avatarpng} alt="profile" />
              <div className="customerDetailPage_body_profile_rating">
                <img src={ratingStar} alt="ratingStar" />
                <p>3.5</p>
              </div>
              {/* <h2 className="customerDetailPage_body_profile_status">{driverDetail?.isApproved ? 'Active' : 'Not Approved'}</h2> */}
            </div>
            <div className="customerDetailPage_body_content">
              <div className="customerDetailPage_body_content_stats">
                <div className="customerDetailPage_body_content_stats_card">
                  <h1>Total Trips Taken</h1>
                  <h2>{driverCompletedRides + driverCancelledRides}</h2>
                </div>
                <div className="customerDetailPage_body_content_stats_card">
                  <h1>Completed Trips</h1>
                  <h2>{driverCompletedRides}</h2>
                </div>
                <div className="customerDetailPage_body_content_stats_card">
                  <h1>Cancelled Trips</h1>
                  <h2>{driverCancelledRides}</h2>
                </div>
              </div>

              <div className="customerDetailPage_body_content_personal">
                <Box sx={{ width: '100%' }}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <StyledTabs value={value} onChange={handleChange} aria-label="styled tabs example">
                      <StyledTab label="Account Information" />
                      <StyledTab label="Wallet Information" />
                    </StyledTabs>
                  </Box>
                  <CustomTabPanel value={value} index={0}>
                    <FormGroup>
                      {/* Identity Information */}
                      <div className="information_container">
                        <h1>Personal detail</h1>

                        <div className="information_container_bottom_line"></div>
                        <div className="customerDetailPage_body_content_personal_col">
                          <div className="customerDetailPage_body_content_personal_col_row">
                            <h1>Driver ID</h1>
                            <h2>{driverDetail?.id}</h2>
                          </div>
                        </div>
                        <div className="customerDetailPage_body_content_personal_col">
                          <div className="customerDetailPage_body_content_personal_col_row">
                            <h1>Name</h1>
                            <h2>{driverDetail?.displayName}</h2>
                          </div>
                          <div className="customerDetailPage_body_content_personal_col_row">
                            <h1>Email</h1>
                            <h2>{driverDetail?.email}</h2>
                          </div>
                        </div>
                        <div className="customerDetailPage_body_content_personal_col">
                          <div className="customerDetailPage_body_content_personal_col_row">
                            <h1>Mobile Number</h1>
                            <h2>{driverDetail?.phoneNumber}</h2>
                          </div>
                          <div className="customerDetailPage_body_content_personal_col_row">
                            <h1>City</h1>
                            <h2>{driverDetail?.city}</h2>
                          </div>
                        </div>
                        <div className="customerDetailPage_body_content_personal_col">
                          <div className="customerDetailPage_body_content_personal_col_row">
                            <h1>State</h1>
                            <h2>{driverDetail?.state}</h2>
                          </div>
                          <div className="customerDetailPage_body_content_personal_col_row">
                            <h1>Country</h1>
                            <h2>{driverDetail?.country}</h2>
                          </div>
                        </div>
                      </div>

                      <div className="information_container documents_information">
                        <div className="information_container_check">
                          <h1>ID Card</h1>
                          {/* <FormControlLabel
                            control={
                              <Checkbox
                                checked={selectedReason === 'Identification'}
                                onChange={rejectionReasonHandler}
                                name="Identification"
                                sx={{
                                  color: '#474747',
                                  '&.Mui-checked': {
                                    color: '#474747'
                                  }
                                }}
                                // checked={true}
                              />
                            }
                          /> */}
                          <button
                            onClick={() => rejectionReasonHandler('Identification')}
                            className="information_container_check_reject_button"
                          >
                            Reject
                          </button>

                          <button
                            className="information_container_check_reset_button"
                            onClick={() => resetSelectedReason('Identification')}
                          >
                            Reset
                          </button>
                        </div>
                        <div className="information_container_bottom_line"></div>
                        <div className="customerDetailPage_body_content_personal_col">
                          <div className="customerDetailPage_body_content_personal_col_row">
                            <h1>ID Card Number</h1>
                            <h2>{driverDocuments?.idNumber}</h2>
                            <p className="customerDetailPage_body_content_personal_col_row_rejectReason">
                              {identificationReason['Licence_Number']}
                            </p>
                          </div>
                          <div className="customerDetailPage_body_content_personal_col_row">
                            <h1>Expiry date</h1>
                            <h2>{driverDocuments?.idExpiryDate}</h2>
                            <p className="customerDetailPage_body_content_personal_col_row_rejectReason">
                              {identificationReason['Date_of_expiry']}
                            </p>
                          </div>
                        </div>
                        <div className="documents_container">
                          <div>
                            <p className="customerDetailPage_body_content_personal_col_row_rejectReason">
                              {identificationReason['Front_Card_Image']}
                            </p>
                            <img
                              src={driverDocuments?.idFront || noImage}
                              alt="document"
                              onClick={() => previewImageModalHandler(driverDocuments?.idFront)}
                            />
                          </div>
                          <div>
                            <p className="customerDetailPage_body_content_personal_col_row_rejectReason">
                              {identificationReason['Back_Card_Image']}
                            </p>
                            <img
                              src={driverDocuments?.idBack || noImage}
                              alt="document"
                              onClick={() => previewImageModalHandler(driverDocuments?.idBack)}
                            />
                          </div>
                        </div>
                      </div>
                      {/* Vehicle Information */}
                      <div className="information_container documents_information">
                        <div className="information_container_check">
                          <h1>Vehicle detail</h1>
                          {/* <FormControlLabel
                            control={
                              <Checkbox
                                checked={selectedReason === 'Vehicle_Detail'}
                                onChange={rejectionReasonHandler}
                                name="Vehicle_Detail"
                                sx={{
                                  color: '#474747',
                                  '&.Mui-checked': {
                                    color: '#474747'
                                  }
                                }}
                              />
                            }
                          /> */}
                          <button
                            onClick={() => rejectionReasonHandler('Vehicle_Detail')}
                            className="information_container_check_reject_button"
                          >
                            Reject
                          </button>
                          <button
                            className="information_container_check_reset_button"
                            onClick={() => resetSelectedReason('Vehicle_Detail')}
                          >
                            Reset
                          </button>
                        </div>

                        <div className="information_container_bottom_line"></div>

                        <div className="customerDetailPage_body_content_personal_col">
                          <div className="customerDetailPage_body_content_personal_col_row">
                            <h1>T.C Number</h1>
                            <h2>{vehicleData?.tcNumber}</h2>
                            <p className="customerDetailPage_body_content_personal_col_row_rejectReason">
                              {vehicalDetailReason['T_C_Number']}
                            </p>
                          </div>
                          <div className="customerDetailPage_body_content_personal_col_row">
                            <h1>Model</h1>
                            <h2>{vehicleData?.model}</h2>
                            <p className="customerDetailPage_body_content_personal_col_row_rejectReason">{vehicalDetailReason['Model']}</p>
                          </div>
                        </div>

                        <div className="customerDetailPage_body_content_personal_col">
                          <div className="customerDetailPage_body_content_personal_col_row">
                            <h1>Year of Expiry</h1>
                            <h2>{vehicleData?.expiryDate}</h2>
                            <p className="customerDetailPage_body_content_personal_col_row_rejectReason">
                              {vehicalDetailReason['Year_of_expiry']}
                            </p>
                          </div>
                          <div className="customerDetailPage_body_content_personal_col_row">
                            <h1>Manufacturer</h1>
                            <h2>{vehicleData?.manufacturer}</h2>
                            <p className="customerDetailPage_body_content_personal_col_row_rejectReason">
                              {vehicalDetailReason['Manufacturer']}
                            </p>
                          </div>
                        </div>
                        <div className="customerDetailPage_body_content_personal_col">
                          <div className="customerDetailPage_body_content_personal_col_row">
                            <h1>Truck Type</h1>
                            <h2>{vehicleData?.truckType}</h2>
                            <p className="customerDetailPage_body_content_personal_col_row_rejectReason">
                              {vehicalDetailReason['Truck_Type']}
                            </p>
                          </div>
                          <div className="customerDetailPage_body_content_personal_col_row">
                            <h1>Color</h1>
                            <h2>{vehicleData?.color}</h2>
                            <p className="customerDetailPage_body_content_personal_col_row_rejectReason">{vehicalDetailReason['Color']}</p>
                          </div>
                        </div>
                        <div className="customerDetailPage_body_content_personal_col">
                          <div className="customerDetailPage_body_content_personal_col_row">
                            <h1>Plate</h1>
                            <h2>{vehicleData?.plate}</h2>
                            <p className="customerDetailPage_body_content_personal_col_row_rejectReason">{vehicalDetailReason['Plate']}</p>
                          </div>
                          <div className="customerDetailPage_body_content_personal_col_row">
                            <h1>Plate Source</h1>
                            <h2>{vehicleData?.plateSource}</h2>
                            <p className="customerDetailPage_body_content_personal_col_row_rejectReason">
                              {vehicalDetailReason['Plate_Source']}
                            </p>
                          </div>
                        </div>
                        <div className="customerDetailPage_body_content_personal_col">
                          <div className="customerDetailPage_body_content_personal_col_row">
                            <h1>Plate Kind</h1>
                            <h2>{vehicleData?.plateKind}</h2>
                            <p className="customerDetailPage_body_content_personal_col_row_rejectReason">
                              {vehicalDetailReason['Plate_Kind']}
                            </p>
                          </div>
                          <div className="customerDetailPage_body_content_personal_col_row">
                            <h1>Plate Number</h1>
                            <h2>{vehicleData?.plateNumber}</h2>
                            <p className="customerDetailPage_body_content_personal_col_row_rejectReason">
                              {vehicalDetailReason['Plate_Number']}
                            </p>
                          </div>
                        </div>

                        <div className="documents_container ">
                          <div>
                            <p className="customerDetailPage_body_content_personal_col_row_rejectReason">
                              {vehicalDetailReason['Card_Front']}
                            </p>
                            <img
                              src={vehicleData?.cardFront || noImage}
                              alt="document"
                              onClick={() => previewImageModalHandler(vehicleData?.cardFront)}
                            />
                          </div>
                          <div>
                            <p className="customerDetailPage_body_content_personal_col_row_rejectReason">
                              {vehicalDetailReason['Card_Back']}
                            </p>
                            <img
                              src={vehicleData?.cardBack || noImage}
                              alt="document"
                              onClick={() => previewImageModalHandler(vehicleData?.cardBack)}
                            />
                          </div>
                        </div>
                      </div>
                      {/* Driving Licence Information */}
                      <div className="information_container documents_information">
                        <div className="information_container_check">
                          <h1>Driving license</h1>
                          {/* <FormControlLabel
                            control={
                              <Checkbox
                                checked={selectedReason === 'Driving_License'}
                                onChange={rejectionReasonHandler}
                                name="Driving_License"
                                sx={{
                                  color: '#474747',
                                  '&.Mui-checked': {
                                    color: '#474747'
                                  }
                                }}
                                // checked={true}
                              />
                            }
                          /> */}
                          <button
                            onClick={() => rejectionReasonHandler('Driving_License')}
                            className="information_container_check_reject_button"
                          >
                            Reject
                          </button>
                          <button
                            className="information_container_check_reset_button"
                            onClick={() => resetSelectedReason('Driving_License')}
                          >
                            Reset
                          </button>
                        </div>
                        <div className="information_container_bottom_line"></div>
                        <div className="customerDetailPage_body_content_personal_col">
                          <div className="customerDetailPage_body_content_personal_col_row">
                            <h1>License Number</h1>
                            <h2>{driverDocuments?.licenseNumber}</h2>
                            <p className="customerDetailPage_body_content_personal_col_row_rejectReason">
                              {drivingLicenceReason['Licence_Number']}
                            </p>
                          </div>
                          <div className="customerDetailPage_body_content_personal_col_row">
                            <h1>Expiry date</h1>
                            <h2>{driverDocuments?.licenseExpiry}</h2>
                            <p className="customerDetailPage_body_content_personal_col_row_rejectReason">
                              {drivingLicenceReason['Date_of_expiry']}
                            </p>
                          </div>
                        </div>

                        <div className="documents_container">
                          <div>
                            <p className="customerDetailPage_body_content_personal_col_row_rejectReason">
                              {drivingLicenceReason['Front_Card_Image']}
                            </p>
                            <img
                              src={driverDocuments?.licenseFront || noImage}
                              alt="document"
                              onClick={() => previewImageModalHandler(driverDocuments?.licenseFront)}
                            />
                          </div>
                          <div>
                            <p className="customerDetailPage_body_content_personal_col_row_rejectReason">
                              {drivingLicenceReason['Back_Card_Image']}
                            </p>
                            <img
                              src={driverDocuments?.licenseBack || noImage}
                              alt="document"
                              onClick={() => previewImageModalHandler(driverDocuments?.licenseBack)}
                            />
                          </div>
                        </div>
                      </div>

                      {/* Bank Information */}
                      <div className="information_container">
                        <div className="information_container_check">
                          <h1>Bank Information</h1>
                          {/* <FormControlLabel
                            control={
                              <Checkbox
                                checked={selectedReason === 'Bank_Information'}
                                onChange={rejectionReasonHandler}
                                name="Bank_Information"
                                sx={{
                                  color: '#474747',
                                  '&.Mui-checked': {
                                    color: '#474747'
                                  }
                                }}
                                // checked={true}
                              />
                            }
                          /> */}
                          <button
                            onClick={() => rejectionReasonHandler('Bank_Information')}
                            className="information_container_check_reject_button"
                          >
                            Reject
                          </button>
                          <button
                            className="information_container_check_reset_button"
                            onClick={() => resetSelectedReason('Bank_Information')}
                          >
                            Reset
                          </button>
                        </div>
                        <div className="information_container_bottom_line"></div>
                        <div className="customerDetailPage_body_content_personal_col">
                          <div className="customerDetailPage_body_content_personal_col_row">
                            <h1>Bank location</h1>
                            <h2>{bankData?.bankLocation}</h2>
                            <p className="customerDetailPage_body_content_personal_col_row_rejectReason">
                              {bankInformationReason['Bank_Location']}
                            </p>
                          </div>
                          <div className="customerDetailPage_body_content_personal_col_row">
                            <h1>Bank Name</h1>
                            <h2>{bankData?.bankName}</h2>
                            <p className="customerDetailPage_body_content_personal_col_row_rejectReason">
                              {bankInformationReason['Bank_Name']}
                            </p>
                          </div>
                        </div>
                        <div className="customerDetailPage_body_content_personal_col">
                          <div className="customerDetailPage_body_content_personal_col_row">
                            <h1>Account holder name</h1>
                            <h2>{bankData?.accountHolderName}</h2>
                            <p className="customerDetailPage_body_content_personal_col_row_rejectReason">
                              {bankInformationReason['Account_Holder_Name']}
                            </p>
                          </div>
                          <div className="customerDetailPage_body_content_personal_col_row">
                            <h1>Account Number</h1>
                            <h2>{bankData?.accountNumber}</h2>
                            <p className="customerDetailPage_body_content_personal_col_row_rejectReason">
                              {bankInformationReason['Account_Number']}
                            </p>
                          </div>
                        </div>
                        <div className="customerDetailPage_body_content_personal_col">
                          <div className="customerDetailPage_body_content_personal_col_row">
                            <h1>IBAN number</h1>
                            <h2>{bankData?.IBAN}</h2>
                            <p className="customerDetailPage_body_content_personal_col_row_rejectReason">
                              {bankInformationReason['IBAN_Number']}
                            </p>
                          </div>
                        </div>

                        <div className="customerDetailPage_body_content_personal_col">
                          {bankData?.bankLocation !== 'Inside UAE' ? (
                            <>
                              <div className="customerDetailPage_body_content_personal_col_row">
                                <h1>SWIFT Code</h1>
                                <h2>{bankData?.swiftCode}</h2>
                                <p className="customerDetailPage_body_content_personal_col_row_rejectReason">
                                  {bankInformationReason['SWIFT_Code']}
                                </p>
                              </div>
                              <div className="customerDetailPage_body_content_personal_col_row">
                                <h1>Bank currency</h1>
                                <h2>{bankData?.bankCurrency}</h2>
                                <p className="customerDetailPage_body_content_personal_col_row_rejectReason">
                                  {bankInformationReason['Bank_Currency']}
                                </p>
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                      <div className="information_container">
                        {/* <div className="information_container_check">
                          <h1>Documents</h1>
                        </div>
                        <div className="information_container_bottom_line"></div> */}
                        <div className="docuemntsclients">
                          {/* <div className="information_container_check">
                            <h1>Bank Certificate</h1>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={selectedReason === 'Bank_Information'}
                                  onChange={rejectionReasonHandler}
                                  name="Bank_Information"
                                  sx={{
                                    color: '#474747',
                                    '&.Mui-checked': {
                                      color: '#474747'
                                    }
                                  }}
                                  // checked={true}
                                />
                              }
                            />
                          </div> */}
                          {/* <div className="information_container_bottom_line"></div> */}
                          <div className="documents_container">
                            <div>
                              <p className="customerDetailPage_body_content_personal_col_row_rejectReason">
                                {bankInformationReason['IBAN_Certificate']}
                              </p>
                              <img
                                src={bankData?.documentPic || noImage}
                                alt="bankData"
                                onClick={() => previewImageModalHandler(bankData?.documentPic)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="detail_page_header_actions">
                        <button
                          disabled={buttonDisable || isFormSubmit}
                          onClick={rejectDriverHandler}
                          className="detail_page_header_actions_reject"
                        >
                          {isFormSubmit ? 'Loading' : 'Save'}
                        </button>

                        {/* <button className="detail_page_header_actions_approve">Approve</button> */}
                      </div>
                    </FormGroup>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={1}>
                    <div className="customerDetailPage_body_content_stats">
                      <div className="customerDetailPage_body_content_stats_card">
                        <h1>Current Amount</h1>
                        <h2>AED 200</h2>
                      </div>
                      <div className="customerDetailPage_body_content_stats_card">
                        <h1>Withdrawal Amount</h1>
                        <h2>AED 45,000</h2>
                      </div>
                      <div className="customerDetailPage_body_content_stats_card">
                        <h1>Shlny Amount</h1>
                        <h2>AED 2000</h2>
                      </div>
                    </div>
                  </CustomTabPanel>
                </Box>
              </div>
            </div>
          </div>
        </>
      )}
      <Modal open={rejectModal} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <ModelRejection>
            <div className="ModelRejection_header">
              <h1>Rejection reasons</h1>
              <div
                className="ModelRejection_header_crossbutton"
                role="button"
                tabIndex={0}
                onKeyDown={(e) => console.log(e)}
                onClick={handleClose}
              >
                X
              </div>
            </div>
            <div className="ModelRejection_body">
              <div className="ModelRejection_body_container">
                <div className="ModelRejection_body_container_col_row">
                  <h1>Request ID: </h1>
                  <h2>{driverDetail?.id}</h2>
                </div>
                <div className="ModelRejection_body_container_col_row">
                  <h1>Request Type:</h1>
                  <h2>{selectedReason.split('_').join(' ')}</h2>
                </div>
              </div>
              <div className="ModelRejection_body_reasons">
                {Object.keys(copiedReasons.reasonValues).map((item, i) => {
                  return (
                    <div key={i} className="ModelRejection_body_reasons_reason">
                      <p>{item.split('_').join(' ')}: </p>
                      <FormControl fullWidth>
                        <InputLabel id={`reason-select-label-${i}`}>Select Reason</InputLabel>
                        <Select
                          labelId={`reason-select-label-${i}`}
                          id={`reason-select-${i}`}
                          label={item}
                          onChange={(event) => reasonSelectHandler(event, item)}
                        >
                          {copiedReasons?.reasonValues[item]?.map((reason, index) => (
                            <MenuItem key={index} value={reason}>
                              {reason}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  );
                })}
              </div>
              <p className="ModelRejection_body_errorMessage">{formError}</p>
              <div className="ModelRejection_body_action">
                <button disabled={isFormSubmit} onClick={rejectDetailHandler} className="ModelRejection_body_action_approve">
                  Reject
                </button>
              </div>
            </div>
          </ModelRejection>
        </Box>
      </Modal>

      <Viewer
        visible={visible}
        drag={false}
        attribute={false}
        rotatable={true}
        scalable={false}
        noClose={true}
        loop={false}
        onMaskClick={() => {
          setVisible(false);
        }}
        onClose={() => {
          setVisible(false);
        }}
        images={[{ src: previewImage, alt: '' }]}
      />
    </DriverDetailPageContainer>
  );
};

export default DriverDetail;
