import React, { useState } from 'react';
import { VehicleContainer } from './style';

import { useNavigate } from 'react-router-dom';
import backArrow from 'assets/shalnyimgages/back arrow.svg';
import upload from 'assets/shalnyimgages/upload-icon.svg';

import { addVehicle } from '../../utils/firebaseDB';
import Swal from 'sweetalert2';

const AddVahicle = () => {
  const navigate = useNavigate();

  const [typeName, setTypeName] = useState('');
  const [costPerKm, setCostPerKm] = useState('');
  const [vahicleImage, setvahicleImage] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [status, setstatus] = useState('Active');
  const [openFee, setOpenFee] = useState('');
  const [loading, setLoading] = useState('');

  const navigateHandler = () => {
    navigate(`/vahicle-table`);
  };

  const formSubmitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    let result = await addVehicle({ typeName, costPerKm, vahicleImage, status, openFee });
    if (result?.status === 200) {
      setLoading(false);
      Swal.fire('Created!', result.message, 'success');
      navigate(`/vahicle-table`);
    } else {
      setLoading(false);
      Swal.fire('Error!', 'Some thing went wrong', 'error');
    }
  };
  const imageUploadHandler = (e) => {
    let file = e.target.files[0];

    const imageUrl = URL.createObjectURL(file);
    setSelectedImage(imageUrl);
    setvahicleImage(file);
  };
  const removeImageHandler = () => {
    setvahicleImage('');
    setSelectedImage(null);
  };
  return (
    <VehicleContainer>
      <div className="vahicle_header">
        <div role="button" onClick={navigateHandler} tabIndex={0} onKeyDown={(e) => console.log(e)}>
          <img src={backArrow} alt="backArrow" />
        </div>
        <h2>Back</h2>
      </div>
      <div className="vahicle_form_container">
        <h1>Add Vehicle Type</h1>
        <form onSubmit={(e) => formSubmitHandler(e)}>
          <div className="vahicle_form_body">
            <div className="vahicle_form_body_inputs">
              <div className="vahicle_form_body_inputs_input">
                <p>Type Name </p>
                <input required name="typeName" type="text" value={typeName} onChange={(e) => setTypeName(e.target.value)} />
              </div>
              <div className="vahicle_form_body_inputs_input">
                <p>Cost Per KM </p>
                <input required name="costPerKm" type="number" value={costPerKm} onChange={(e) => setCostPerKm(e.target.value)} />
              </div>

              <div className="vahicle_form_body_inputs_input">
                <p>Select Status </p>
                <select value={status} onChange={(e) => setstatus(e.target.value)} required>
                  <option name="active" value="Active">
                    Active
                  </option>
                  <option name="deactive" value="Deactive">
                    Deactive
                  </option>
                </select>
              </div>
              <div className="vahicle_form_body_inputs_input">
                <p>Open Fee</p>
                <input required name="openFee" type="number" value={openFee} onChange={(e) => setOpenFee(e.target.value)} />
              </div>
            </div>
            <div className="vahicle_form_body_second">
              <div className="vahicle_form_body_second_upload">
                {!selectedImage ? (
                  <div className="file-upload">
                    <label htmlFor="upload" className="file-upload__label">
                      <div className="vahicle_form_body_second_upload_btn">
                        <img src={upload} alt="backArrow" />
                        <p>Browse to upload</p>
                      </div>
                    </label>
                    <input
                      id="upload"
                      className="file-upload__input"
                      required
                      type="file"
                      name="vahicleImage"
                      onChange={imageUploadHandler}
                    />
                  </div>
                ) : (
                  <div className="vahicle_form_body_second_upload_image">
                    <button
                      tabIndex={-42}
                      onClick={removeImageHandler}
                      onKeyDown={(e) => console.log(e)}
                      className="vahicle_form_body_second_upload_image_crossButton"
                    >
                      X
                    </button>
                    <img src={selectedImage} alt="Selected" />
                  </div>
                )}
              </div>

              <input disabled={loading} type="submit" value={loading ? 'Loading...' : 'Add'} className="vahicle_form_body_second_button" />
            </div>
          </div>
        </form>
      </div>
    </VehicleContainer>
  );
};

export default AddVahicle;
