import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import ProtectedRoute from 'utils/protected_routes';
import Rides from 'pages/rides/index';
import RideDetail from 'pages/rides/ridesDetail';
import Customer from 'pages/customer';
import CustomerDetail from 'pages/customer/customerDetail';
import AddVehicle from 'pages/Vehicle/addVahicle';
import VehicleTable from 'pages/Vehicle';
import DriverRequest from 'pages/driverRequest';
import RequestDetail from 'pages/driverRequest/requestDetail';
import Drivers from 'pages/drivers';
import DriverDetail from 'pages/drivers/driverDetail';
import InsuranceComapanies from 'pages/insuranceComapanies';
import InsuranceComapanyDetail from 'pages/insuranceComapanies/companyDetail';
import AddCompany from 'pages/insuranceComapanies/addCompany';
import Alerts from 'pages/alerts';
import AddNewAlert from 'pages/alerts/addNewAlert';
import SupportRequest from 'pages/supportRequests';
import PanelManagment from 'pages/panelManagement';

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));

// render - sample page
const SamplePage = Loadable(lazy(() => import('pages/extra-pages/SamplePage')));

const MainRoutes = {
  path: '/',
  element: (
    <ProtectedRoute>
      <MainLayout></MainLayout>
    </ProtectedRoute>
  ),
  children: [
    {
      path: 'dashboard',
      element: <DashboardDefault />
    },
    {
      path: 'rides',
      element: <Rides />
    },

    {
      path: 'customers',
      element: <Customer />
    },
    {
      path: 'customer/detail/:id',
      element: <CustomerDetail />
    },
    {
      path: 'add-vahicle',
      element: <AddVehicle />
    },
    {
      path: 'vahicle-table',
      element: <VehicleTable />
    },
    {
      path: 'rides/detail/:id',
      element: <RideDetail />
    },

    {
      path: 'drivers',
      element: <Drivers />
    },
    {
      path: 'insurance-companies',
      element: <InsuranceComapanies />
    },
    {
      path: 'insurance-companies/detail/:id',
      element: <InsuranceComapanyDetail />
    },
    {
      path: 'new-insurance-companies',
      element: <AddCompany />
    },
    {
      path: 'drivers/detail/:id',
      element: <DriverDetail />
    },
    {
      path: 'driver_requests',
      element: <DriverRequest />
    },
    {
      path: 'driver_requests/request/:id',
      element: <RequestDetail />
    },
    {
      path: 'notificatin_alerts',
      element: <Alerts />
    },
    {
      path: 'addNew',
      element: <AddNewAlert />
    },
    {
      path: 'support_requests',
      element: <SupportRequest />
    },
    {
      path: 'panel_management',
      element: <PanelManagment />
    },
    {
      path: '*',
      element: <SamplePage />
    }
  ]
};

export default MainRoutes;
