import styled from 'styled-components';

const CustomTableContainer = styled.div`
  box-shadow: 0px 0px 30px 0px #2f2d6b1a;
  padding: 25px 13px;
  border-radius: 20px;

  h1 {
    font-family: DM Sans;
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    margin-bottom: 20p;
  }
`;

const CustomerDetailContainer = styled.div`
  .customerDetailPage_Header {
    display: flex;
    align-items: center;
    gap: 10px;
    h2 {
      font-family: DM Sans;
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      margin-top: -6px;
    }
  }
  .customerDetailPage_body {
    display: grid;
    grid-template-columns: 200px 1fr;
    margin-top: 30px;
    gap: 20px;
    &_profile {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
      &_status {
        color: #34995c;
        border-radius: 8px;
        background: rgba(33, 150, 83, 0.15);
        width: max-content;
        color: var(--Success-Success-Dark, #34995c);
        text-align: center;
        font-family: DM Sans;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px; /* 144.444% */
        letter-spacing: 0.2px;
        padding: 7px 16px;
      }
    }
    &_content {
      &_stats {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
        &_card {
          border-radius: 20px;
          border: 1px solid var(--Text-Color-Text-Color-6, #f9f9f9);
          background: var(--Neutral-Color-Pure-White, #fff);
          box-shadow: 0px 0px 30px 0px rgba(47, 45, 107, 0.1);
          min-height: 115px;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          padding: 0 20px;
          h1 {
            color: var(--Secondary-Color-Secondary-2, #494949);
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 120% */
            color: #494949;
          }
          h2 {
            color: var(--Secondary-Color-Secondary-2, #494949);
            font-size: 24px;
            font-style: normal;
            color: #494949;
            font-weight: 700;
            line-height: 32px; /* 133.333% */
          }
        }
      }
      &_personal {
        border-radius: 20px;
        background: #fff;
        box-shadow: 0px 0px 30px 0px rgba(47, 45, 107, 0.1);
        padding: 20px;
        margin-top: 20px;
        h1 {
          color: #161616;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px; /* 155.556% */
        }
        &_col {
          display: grid;
          grid-template-columns: 1fr 1fr;
          margin-top: 20px;
          &_column {
            &_row {
              display: grid;
              grid-template-columns: 1fr 1fr;
              h1 {
                color: #868686;
                font-family: DM Sans;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 19px; /* 118.75% */
              }
              h2 {
                color: var(--black, #3e4756);
                font-family: DM Sans;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 13px; /* 81.25% */
              }
            }
          }
        }
      }
      &_rides {
        border-radius: 20px;
        background: #fff;
        box-shadow: 0px 0px 30px 0px rgba(47, 45, 107, 0.1);
        padding: 20px;
        margin-top: 20px;
        h1 {
          color: #161616;
          font-family: DM Sans;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px; /* 155.556% */
        }
      }
    }
  }

  
  .request_detail_handler {
    border: 1px solid #000000;
    background: #ffffff;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.20000000298023224px;
    text-align: center;
    border-radius: 7px;
    cursor: pointer;
  }
  .customerDiriverLocation {
    .ridesTableLocation {
      display: flex;
      align-items: center;
      gap: 10px;
      margin: 5px 0;
    }
  }
`;

export { CustomTableContainer, CustomerDetailContainer };
