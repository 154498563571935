import styled from 'styled-components';

const VehicleContainer = styled.div`
  .vahicle_header {
    display: flex;
    align-items: center;
    gap: 21px;
  }

  h2 {
    font-family: DM Sans;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    margin-bottom: 5px;
  }

  .vahicle_form_container {
    h1 {
      font-family: DM Sans;
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      margin: 23px 0;
    }
  }
  .vahicle_form_body {
    box-shadow: 0px 0px 30px 0px #2f2d6b1a;
    padding: 38px;
    border-radius: 20px;
    display: flex;
    gap: 150px;

    &_inputs {
      width: 100%;
      &_input {
        margin-bottom: 19px;
        input {
          width: 100%;
          height: 100%;
          border: 1px solid #e0e0e0;
          outline: none;
          padding: 13px 8px;
          border-radius: 4px;
        }
        select {
          width: 100%;
          height: 100%;
          border: 1px solid #e0e0e0;
          outline: none;
          padding: 13px 8px;
          border-radius: 4px;
        }
        p {
          font-family: DM Sans;
          font-size: 16px;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0px;
          text-align: left;
          margin-bottom: 8px;
        }
      }
    }
    &_second {
      width: 100%;

      &_upload {
        width: 100%;
        border: 1px solid #e0e0e0;
        height: 242px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;

        .file-upload {
          position: relative;
          display: inline-block;
        }

        .file-upload__label {
          display: block;
          padding: 1em 2em;
          color: #000;
          border-radius: 0.4em;
          transition: background 0.3s;
        }

        .file-upload__input {
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          font-size: 1;
          width: 0;
          height: 100%;
          opacity: 0;
        }
        &_image {
          width: auto;
          height: 100%;
          position: relative;

          img {
            width: 100%;
            height: 100%;
          }
          &_crossButton {
            position: absolute;
            right: 5px;
            top: 5px;
            background-color: black;
            color: #ffffff;
            font-weight: bolder;
            width: 20px;
            height: 20px;
            text-align: center;
            border-radius: 100%;
            cursor: pointer;
            border: none;
          }
        }

        &_btn {
          border: 1px dashed #dadadb;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          padding: 20px 30px;
          border-radius: 10px;
          cursor: pointer;

          img {
            width: 18px;
            height: 14px;
            margin-bottom: 6px;
          }
          p {
            font-family: DM Sans;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0em;
            text-align: center;
          }
        }
      }

      &_button {
        background-color: #000000;
        border: none;
        font-family: SF Pro Display;
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: 0px;
        text-align: center;
        color: #ffffff;
        padding: 12px 51px;
        border-radius: 10px;
        float: right;
        margin-top: 90px;
      }
    }
  }
`;

const CustomTableContainer = styled.div`
  box-shadow: 0px 0px 30px 0px #2f2d6b1a;
  padding: 25px 13px;
  border-radius: 20px;

  h1 {
    font-family: DM Sans;
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    margin-bottom: 20p;
  }

  .vehicle_header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      background: #000000;
      font-family: DM Sans;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0px;
      text-align: center;
      color: #ffffff;
      padding: 10px 14px;
      border: none;
      border-radius: 8px;
      cursor: pointer;
    }
  }
  .vehicleActions {
    display: flex;
    align-items: center;
    gap: 15px;
    img {
      cursor: pointer;
      width: 25px;
      height: 25px;
    }
  }
  .vehicle_delete_handler {
    background: transparent;
    border: none;
    cursor: pointer;
    width: min-content;
    padding: 0;
  }
`;

export { VehicleContainer, CustomTableContainer };
