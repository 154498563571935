import React, { useState } from 'react';
import { InsuranceCompanyContainer } from './style';

import { useNavigate } from 'react-router-dom';
import backArrow from 'assets/shalnyimgages/back arrow.svg';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../utils/firebase';
import { addCompany } from '../../utils/firebaseDB';
import { postRequest } from '../../utils/apiRequests';
import Swal from 'sweetalert2';
const AddCompany = () => {
  const navigate = useNavigate();

  const [companyName, setCompanyName] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [status, setstatus] = useState('');
  const [loading, setloading] = useState(false);

  function generateRandomAlphaNumericText(length) {
    const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randomText = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      randomText += charset[randomIndex];
    }

    return randomText;
  }

  const navigateHandler = () => {
    navigate(`/insurance-companies`);
  };

  const formSubmitHandler = async (e) => {
    e.preventDefault();
    setloading(true);
    const password = generateRandomAlphaNumericText(8);

    await createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        const user = userCredential.user;

        // setUserRole(user?.uid, 'insurace_company');

        const response = await postRequest(
          'insurance_email',
          {
            email: email,
            password: password
          },
          {
            'Content-Type': 'application/json'
          }
        );
        if (response.success) {
          let data = { companyName, contactNumber, address, email, status, user: user?.uid };
          let result = await addCompany(data);
          if (result.status == 200) {
            setloading(false);
            Swal.fire('Created!', result.message, 'success');
            navigate(`/insurance-companies`);
          }
        } else {
          setloading(true);
          Swal.fire('Error!', 'Some thing went wrong', 'error');
        }
      })
      .catch((error) => {
        setloading(false);
        if (error.code === 'auth/email-already-in-use') {
          alert('User Already Exist, Please use any other Email');
        }
      });
  };
  return (
    <InsuranceCompanyContainer>
      <div className="vahicle_header">
        <div role="button" onClick={navigateHandler} tabIndex={0} onKeyDown={(e) => console.log(e)}>
          <img src={backArrow} alt="backArrow" />
        </div>
        <h2>Back</h2>
      </div>
      <div className="vahicle_form_container">
        <h1>Add Insurance Company</h1>
        <form onSubmit={formSubmitHandler}>
          <div className="vahicle_form_body">
            <div className="vahicle_form_body_inputs">
              <div className="vahicle_form_body_inputs_input">
                <p>Company Name </p>
                <input required name="typeName" type="text" value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
              </div>
              <div className="vahicle_form_body_inputs_input">
                <p>Mobile Number</p>
                <input required name="costPerKm" type="number" value={contactNumber} onChange={(e) => setContactNumber(e.target.value)} />
              </div>

              <div className="vahicle_form_body_inputs_input">
                <p>Select Status </p>
                <select value={status} onChange={(e) => setstatus(e.target.value)} required>
                  <option name="active" value="Active">
                    Active
                  </option>
                  <option name="deactive" value="Deactive">
                    Deactive
                  </option>
                </select>
              </div>
            </div>
            <div className="vahicle_form_body_inputs">
              <div className="vahicle_form_body_inputs_input">
                <p>Address </p>
                <input required name="typeName" type="text" value={address} onChange={(e) => setAddress(e.target.value)} />
              </div>
              <div className="vahicle_form_body_inputs_input">
                <p>Email </p>
                <input required name="costPerKm" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
              </div>
              {/* <button disabled={loading} type="button" onClick={formSubmitHandler} className="vahicle_form_body_second_button">
              {loading ? 'Loading...' : 'Add'}
            </button> */}
              <input disabled={loading} type="submit" value={loading ? 'Loading...' : 'Add'} className="vahicle_form_body_second_button" />
            </div>
          </div>
        </form>
      </div>
    </InsuranceCompanyContainer>
  );
};

export default AddCompany;
