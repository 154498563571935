import PropTypes from 'prop-types';

// material-ui
// import { useTheme } from '@mui/material/styles';
import { Stack } from '@mui/material';

// project import
// import DrawerHeaderStyled from './DrawerHeaderStyled';
// import Logo from 'components/Logo';
import Logo from 'assets/shalnyimgages/shalny_logo.png';

// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = () => {
  // const theme = useTheme();

  return (
 
       <Stack mt={4} mb={4} direction="row" spacing={1} justifyContent="center" marg > 
       <img width={158} src={Logo} alt="Logo" />
       </Stack> 


   

  );
};

DrawerHeader.propTypes = {
  open: PropTypes.bool
};

export default DrawerHeader;
