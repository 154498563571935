import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { getDataFromCollection, deleteDocumentHandler } from '../../utils/firebaseDB';
import { CustomTableContainer } from './style';
import Swal from 'sweetalert2';
import Loader from 'components/loading/loading';
import { useNavigate } from 'react-router-dom';

import deleteIcon from 'assets/shalnyimgages/delete.svg';

const columns = [
  { id: 'id', label: 'ID' },
  { id: 'carimage', label: 'Vehicle' },
  { id: 'name', label: 'Name' },
  { id: 'openFee', label: 'Open Fee' },
  { id: 'costPerKm', label: 'Cost Per Km' },
  { id: 'status', label: 'Status' },
  { id: 'action', label: 'Action' }
];

export default function StickyHeadTable() {
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(14);
  const [userData, setuserData] = React.useState([]);
  const [vehicleDataLoading, setvehicleDataLoading] = React.useState(true);

  React.useEffect(() => {
    getDataFromCollection('vehicles')
      .then((result) => {
        setvehicleDataLoading(false);
        if (result.length) {
          const newArr = result.map((vehicle) => {
            return {
              id: vehicle.id,
              carimage: vehicle.vahicleImage,
              name: vehicle?.typeName || 'Alto',
              openFee: `${vehicle?.openFee} Kg`,
              costPerKm: `$${vehicle.costPerKm}`,
              status: vehicle.status
            };
          });

          setuserData(newArr);
        }
      })
      .catch((error) => {
        setvehicleDataLoading(false);
        console.error('Error fetching data:', error);
      });
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const vehicleAddHandker = () => {
    navigate('/add-vahicle');
  };

  const deleteHandler = (vehicle) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const deleteVehicle = userData.filter((vehi) => vehi.id !== vehicle.id);
        setuserData(deleteVehicle);
        await deleteDocumentHandler('vehicles', vehicle.id);
        Swal.fire('Deleted!', 'Vehicle type has been deleted.', 'success');
      }
    });
  };

  return (
    <CustomTableContainer>
      <div className="vehicle_header">
        <h1>Vehicle Type</h1>
        <button onClick={vehicleAddHandker}>Add Vehicle Type</button>
      </div>
      {vehicleDataLoading ? (
        <Loader />
      ) : (
        <>
          {' '}
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {userData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.id === 'id' ? (
                              i + 1
                            ) : column.id === 'carimage' ? (
                              <img width="50" height="50" src={row.carimage} alt={row.name} />
                            ) : column.id === 'action' ? (
                              <div className="vehicleActions">
                                {/* <img src={edit} alt="edit" /> */}
                                <button onClick={() => deleteHandler(row)} className="vehicle_delete_handler">
                                  {' '}
                                  <img src={deleteIcon} alt="total_ridesaa" />
                                </button>
                              </div>
                            ) : column.format && typeof value === 'number' ? (
                              column.format(value)
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[14, 25, 100]}
            component="div"
            count={userData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </CustomTableContainer>
  );
}
