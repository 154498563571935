import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyC0cuX2UFiPCXF0icDjeEfNKkX5kbwAMHc',
  authDomain: 'shlny-ed2c9.firebaseapp.com',
  projectId: 'shlny-ed2c9',
  storageBucket: 'shlny-ed2c9.appspot.com',
  messagingSenderId: '441497763169',
  appId: '1:441497763169:web:54a759d727c44f701b1920',
  measurementId: 'G-EQGM13LWVS'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const storage = getStorage(app);

export const auth = getAuth(app);

// const user = firebase.auth().currentUser;

export async function deleteAuthenticationUser() {

  try{
    const t =await getAuth(app);
    console.log(t);
    // const res = await getAuth().deleteUser('J8gEPpWv0ab0GCUt9WxIOCyJ5KA2');
    console.log("res", res)
  }catch(err){
    console.log("err",err)
  }

  
  // deleteUser('kwsjrXbxWDW2AR3fZSDj9r7Qz1g2').then((response) => {
  //   console.log(response , 'responseresponse');
  // }).catch((error) => {
  //   console.log(error , 'errorerrorerror');
  // });

  // getAuth().deleteUsers(['kwsjrXbxWDW2AR3fZSDj9r7Qz1g2'])
  //   .then(() => {
  //     console.log('Successfully deleted user');
  //   })
  //   .catch((error) => {
  //     console.log('Error deleting user:', error);
  //   });

  // auth.deleteUsers(['kwsjrXbxWDW2AR3fZSDj9r7Qz1g2'])
  //   .then(() => {
  //     console.log('Successfully deleted user');
  //   })
  //   .catch((error) => {
  //     console.log('Error deleting user:', error);
  //   });

  // user.delete()
  //   .then(() => {
  //     console.log('-----------------');
  //   })
  //   .catch((error) => {
  //     console.log(error, 'errorerror');
  //   });
}

export default app;
