// project import
// import pages from './pages';
// import dashboard from './dashboard';
import utilities from './utilities';
// import support from './support';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [utilities]
};

export default menuItems;
