import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, doc, getDoc, addDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { storage } from './firebase';
import { getAuth } from 'firebase/auth';

// import { v4 } from 'uuid';
const firebaseConfig = {
  apiKey: 'AIzaSyC0cuX2UFiPCXF0icDjeEfNKkX5kbwAMHc',
  authDomain: 'shlny-ed2c9.firebaseapp.com',
  projectId: 'shlny-ed2c9',
  storageBucket: 'shlny-ed2c9.appspot.com',
  messagingSenderId: '441497763169',
  appId: '1:441497763169:web:54a759d727c44f701b1920',
  measurementId: 'G-EQGM13LWVS'
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// Function to get data from a Firestore collection
export async function getDataFromCollection(collectionName) {
  try {
    const data = [];
    const querySnapshot = await getDocs(collection(db, collectionName));

    querySnapshot.forEach((doc) => {
      data.push({
        id: doc.id,
        ...doc.data()
      });
    });

    return data;
  } catch (error) {
    console.error('Error getting data from Firestore:', error);
    throw error;
  }
}

export async function getDocumentDetail(collectionName, documentId) {
  const docRef = doc(db, collectionName, documentId);

  try {
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      return data;
    } else {
      return {};
    }
  } catch (error) {
    console.error('Error getting data from Firestore:', error);
    throw error;
  }
}

export async function updateCollectionWithData(collectionName, _Id, fieldToUpdate, newValue) {
  try {
    const docRef = doc(db, collectionName, _Id);
    await updateDoc(docRef, { [fieldToUpdate]: newValue });
    const updatedDoc = await getDoc(docRef);
    const updatedData = updatedDoc.data();
    return updatedData;

    // const docRef = await updateDoc(doc(db, collectionName, _Id), { [fieldToUpdate]: newValue });
  } catch (error) {
    console.error('Error updating field:', error);
  }
}

export async function addCompany(data) {
  const docRef = await addDoc(collection(db, 'insuranceComapanies'), data);

  if (docRef?.id) {
    return {
      status: 200,
      message: 'Successfully Created'
    };
  } else {
    alert('Some thing went wrong');
  }
}

export async function createNewAlert(data) {
  const docRef = await addDoc(collection(db, 'alerts'), data);

  if (docRef?.id) {
    return {
      status: 200,
      message: 'Successfully Created'
    };
  } else {
    alert('Some thing went wrong');
  }
}
export async function createPanelUser(data) {
  const docRef = await addDoc(collection(db, 'panelUser'), data);

  if (docRef?.id) {
    return {
      status: 200,
      message: 'Successfully Created'
    };
  } else {
    alert('Some thing went wrong');
  }
}

export async function addVehicle(data) {
  const storageRef = ref(storage, data.vahicleImage.name);
  // const storageRef = ref(storage, `items/${data.vahicleImage.name + v4()}`);
  const uploadTask = uploadBytesResumable(storageRef, data.vahicleImage);
  let downloadedURL = await getDownloadURL(uploadTask.snapshot.ref);
  let obj = { ...data, vahicleImage: downloadedURL };
  const docRef = await addDoc(collection(db, 'vehicles'), obj);
  if (docRef?.id) {
    return {
      status: 200,
      message: 'Successfully Created'
    };
  } else {
    alert('Some thing went wrong');
  }
  uploadTask.on(
    'state_changed',
    // (snapshot) => {

    //   const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    //   console.log('upload is' + progress + '% done');
    //   switch (snapshot.state) {
    //     case 'paused':
    //       console.log('Upload paused');
    //       break;
    //     case 'running':
    //       console.log('Upload running');
    //       break;
    //     default:
    //       break;
    //   }
    // },
    // (error) => {
    //   console.log(error);
    // },
    () => {
      console.log('four');
      // getDownloadURL(uploadTask.snapshot.ref).then(async (downloadedURL) => {
      //   let obj = { ...data, vahicleImage: downloadedURL };
      //   console.log('three');
      // const docRef = await addDoc(collection(db, 'vehicles'), obj);
      // console.log('four');
      // if (docRef?.id) {
      //   alert('Successfully Added');
      // } else {
      //   alert('Some thing went wrong');
      // }

      // try {
      //   const docSnap = await getDoc(docRef);

      //   if (docSnap.exists()) {
      //     const data = docSnap.data();
      //     console.log(data);
      //   } else {
      //     return {};
      //   }
      // } catch (error) {
      //   console.error('Error getting data from Firestore:', error);
      //   throw error;
      // }
      // });
    }
  );
}
export async function addCustomer(data) {
  const docRef = await addDoc(collection(db, 'users'), { ...data, userType: 'insuredUser' });
  if (docRef?.id) {
    return {
      status: 200,
      message: 'Successfully Created'
    };
  } else {
    alert('Some thing went wrong');
  }
}

// Set the user's role in the database
export async function addRolesWithUserId(userId, role ,route) {
  let data = {
    userId,
    role,
    route
  };
  return await addDoc(collection(db, 'roles'), data);
}

// Retrieve user role from the database
export async function getUserRole(collectionName, userId) {
  let selectedRole = {};
  const querySnapshot = await getDocs(collection(db, collectionName));
  querySnapshot.forEach((doc) => {
    if (doc.data().userId === userId) {
      selectedRole = doc.data();
    }
  });

  return selectedRole;
}

export async function getCustomerRides(collectionName, customerId) {
  let selectedRole = [];
  const querySnapshot = await getDocs(collection(db, collectionName));
  querySnapshot.forEach((doc) => {
    if (doc.data().pessangerUid === customerId) {
      selectedRole.push(doc.data());
    }
  });

  return selectedRole;
}
export async function getDetails(collectionName, uid) {
  let detail = {};
  const querySnapshot = await getDocs(collection(db, collectionName));
  querySnapshot.forEach((doc) => {
    if (doc.data().uid === uid) {
      detail = doc.data();
    }
  });

  return detail;
}

export async function getDriverRides(collectionName, uid) {
  let rides = [];
  const querySnapshot = await getDocs(collection(db, collectionName));
  querySnapshot.forEach((doc) => {
    if (doc.data().driverUid === uid) {
      rides.push(doc.data());
    }
  });

  return rides;
}
export async function deleteDocumentHandler(collectionName, documentId) {
  await deleteDoc(doc(db, collectionName, documentId));
}
export async function deleteAuthenticationUser() {
  // deleteUser('kwsjrXbxWDW2AR3fZSDj9r7Qz1g2').then((response) => {
  //   console.log(response , 'responseresponse');
  // }).catch((error) => {
  //   console.log(error , 'errorerrorerror');
  // });
  try{
    const res = await getAuth().deleteUser('J8gEPpWv0ab0GCUt9WxIOCyJ5KA2');
    console.log("res", res)
  }catch(err){
    console.log("err",err)
  }
  
  
}
