import styled from 'styled-components';

const AlertsContainer = styled.div`
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 30px 0px rgba(47, 45, 107, 0.1);
  padding: 25px 13px;
  .alertHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h1 {
      font-family: DM Sans;
      font-size: 24px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0em;
      margin-bottom: 20p;
    }

    button {
      background: #000000;
      font-family: DM Sans;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0px;
      text-align: center;
      color: #ffffff;
      padding: 10px 14px;
      border: none;
      border-radius: 8px;
      cursor: pointer;
    }
  }
  .textellipses {
    display: block;
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .action_button {
    border: 1px solid #000000;
    background: #ffffff;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.20000000298023224px;
    text-align: center;
    border-radius: 7px;
    cursor: pointer;
  }
`;

const AlertFormContainer = styled.div`
  max-width: 950px;
  .alertForm {
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 0px 30px 0px rgba(47, 45, 107, 0.1);
    padding: 24px;

    h1 {
      color: #838181;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      margin-bottom: 8px;
    }

    &_textarea {
      color: var(--Secondary-Color-Secondary-3, var(--grey, #000000));
      font-family: DM Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      outline: none;
      width: 100%;
      border-radius: 10px;
      padding: 10px;
      max-width: 900px;
      ::placeholder {
        color: var(--Secondary-Color-Secondary-3, var(--grey, #b6b6b6));
      }
    }
  }
  .assignto_form {
    margin: 10px 0;
  }
  .alertformactionbutton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    &_cancel {
      background: #ffffff;
      font-family: DM Sans;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0px;
      text-align: center;
      color: #000000;
      padding: 10px 14px;
      border: 1px solid black;
      border-radius: 8px;
      cursor: pointer;
    }
    &_submit {
      background: #000000;
      font-family: DM Sans;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0px;
      text-align: center;
      color: #ffffff;
      padding: 10px 14px;
      border: none;
      border-radius: 8px;
      cursor: pointer;
    }
  }

`;

export { AlertsContainer, AlertFormContainer };
