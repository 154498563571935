import React, { useEffect, useState, useCallback } from 'react';
import { AlertFormContainer } from './style';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { createNewAlert, getDocumentDetail } from '../../utils/firebaseDB';
import { useNavigate, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import moment from 'moment';

const AddNewAlert = () => {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(useLocation().search).get('alertId');
  const [description, setdescription] = useState('');
  const [alertOption, setAlertOption] = useState('driver');
  const [loading, setloading] = useState(false);

  const radioOptionsHandler = (event) => {
    setAlertOption(event.target.value);
  };

  const fetchUserdetail = useCallback((id) => {
    getDocumentDetail('alerts', id)
      .then((response) => {
        setAlertOption(response.alertOption);
        setdescription(response.description);
      })
      .catch((error) => {
        console.log(error, 'errorerror');
        navigate(`/notificatin_alerts`);
      });
  }, []);

  useEffect(() => {
    if (urlParams) {
      fetchUserdetail(urlParams);
    }
  }, [urlParams]);

  const formSubmitHandler = async (event) => {
    event.preventDefault();

    let result = await createNewAlert({ description, alertOption, createdAt: moment().format('L') });
    if (result.status == 200) {
      setloading(false);
      Swal.fire('Created!', result.message, 'success');
      navigate(`/notificatin_alerts`);
    }
  };

  const navigateHandler = () => {
    navigate(`/notificatin_alerts`);
  };
  return (
    <AlertFormContainer>
      <div className="alertForm">
        <form onSubmit={formSubmitHandler}>
          <h1>Description</h1>
          <textarea
            className="alertForm_textarea"
            placeholder="write text here..."
            value={description}
            rows="8"
            onChange={(e) => setdescription(e.target.value)}
          />
          <div className="assignto_form">
            <h1>Assign To</h1>
            <FormControl>
              <RadioGroup
                value={alertOption}
                onChange={radioOptionsHandler}
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                row
              >
                <FormControlLabel value="driver" control={<Radio />} label="Driver" />
                <FormControlLabel value="customer" control={<Radio />} label="Customer" />
                <FormControlLabel value="both" control={<Radio />} label="Both" />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="alertformactionbutton">
            <button onClick={navigateHandler} type="button" className="alertformactionbutton_cancel">
              Cancel
            </button>
            {urlParams ? null : <input loading={loading} className="alertformactionbutton_submit" type="submit" value="Submit" />}
          </div>
        </form>
      </div>
    </AlertFormContainer>
  );
};

export default AddNewAlert;
