import styled from 'styled-components';

const RidesDetailMain = styled.div`
  .detail_page_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &_back {
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      h2 {
        font-family: DM Sans;
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
    &_status {
      display: flex;
      align-items: center;
      gap: 21px;
      h1 {
        font-family: DM Sans;
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
      }
      &_completed {
        font-family: DM Sans;
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.20000000298023224px;
        text-align: center;
        padding: 4px 16px 4px 16px;
        border-radius: 8px;
        color: #ffffff;
        background-color: #219653;
      }
      &_placed {
        font-family: DM Sans;
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.20000000298023224px;
        text-align: center;
        padding: 4px 16px 4px 16px;
        border-radius: 8px;
        color: #ffffff;
        background-color: #f18827;
      }
      &_cancelled {
        font-family: DM Sans;
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.20000000298023224px;
        text-align: center;
        padding: 4px 16px 4px 16px;
        border-radius: 8px;
        color: #ffffff;
        background-color: #f41f1f;
      }
    }
  }
  .detail_page_content {
    background-color: #ffffff;
    padding: 16px;
    margin-top: 20px;
    border-radius: 20px;
    .ride_detail {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 30px;
      margin-top: 20px;
      .ride_detail_user {
        display: flex;
        flex-direction: column;
        gap: 25px;
        .ride_row {
          display: grid;
          grid-template-columns: 1fr 2fr;

          h1 {
            font-family: DM Sans;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            color: #868686;
          }
          p {
            font-family: DM Sans;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            color: #3e4756;
          }
        }
      }
      .ride_detail_vehical {
        display: flex;
        flex-direction: column;
        gap: 25px;
        .ride_row {
          display: grid;
          grid-template-columns: 1fr 1fr;
          h1 {
            font-family: DM Sans;
            font-size: 16px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: left;
            color: #868686;
          }
          p {
            font-family: DM Sans;
            font-size: 16px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: left;
            color: #3e4756;
          }
        }
      }
    }
  }
`;

export { RidesDetailMain };
