// material-ui
import { Box } from '@mui/material';

// project import
import NavGroup from './NavGroup';
import menuItem from 'menu-items';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  const getInuranceCompanyRotes = menuItem.items.filter((routeId) => routeId.id === 'insuranceComapany');
  const navGroups = getInuranceCompanyRotes.map((item) => {
    return <NavGroup key={item.id} item={item} />;
    // switch (item.type) {
    //   case 'group':
    //     return <NavGroup key={item.id} item={item} />;
    //   default:
    //     return (
    //       <Typography key={item.id} variant="h6" color="error" align="center">
    //         Fix - Navigation Group
    //       </Typography>
    //     );
    // }
  });

  return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

export default Navigation;
