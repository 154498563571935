import styled from 'styled-components';

const CustomTableContainer = styled.div`
  box-shadow: 0px 0px 30px 0px #2f2d6b1a;
  padding: 25px 13px;
  border-radius: 20px;

  h1 {
    font-family: DM Sans;
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    margin-bottom: 20p;
  }
  .request_detail_handler {
    border: 1px solid #000000;
    background: #ffffff;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.20000000298023224px;
    text-align: center;
    border-radius: 7px;
    cursor: pointer;
  }

  .table_search_input{
    width: 100%;
    border: 1px solid #B6B6B6;
    border-radius: 5px;
    padding: 0 10px;
    outline:  none;
    color: #B6B6B6;
    font-size: 16px;
  }

  .successTableStatus {
    text-align: center;
    color: #25c858;
    background-color: #f0f8f2;
    border-radius: 5px;
    padding: 2px 4px;
    font-size: 12px;
  }
  .cancelTableStatus {
    text-align: center;
    color: #f41f1f;
    background-color: #fae9ea;
    border-radius: 5px;
    padding: 2px 4px;
    font-size: 12px;
  }
  .placesTableStaus{
    text-align: center;
    color: #F18827;
    background-color: #FFF7F0;
    border-radius: 5px;
    padding: 2px 4px;
    font-size: 12px;
  }
  .actionDots {
    cursor: pointer;
  }
  .ridesTableLocation{
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .rider_table_filter{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
`;

export { CustomTableContainer };
